export enum FormHeaderStatusClassMapping {
  'Pending Approval'='PENDING_STATUS',
  'In Review'='PENDING_STATUS',
  'In Progress'='PENDING_STATUS',
  'IN REVIEW' = 'PENDING_STATUS',
  'Pending Change'='WIP_STATUS',
  'PENDING CHANGE'='WIP_STATUS',
  'IN_REVIEW' = 'PENDING_STATUS',
  'Approved'='PENDING_STATUS',
  'Quarantine' = 'PENDING_STATUS',
  'Accepted'='GREEN_STATUS',
  'Released'='GREEN_STATUS',
  'RELEASED'='GREEN_STATUS',
  'Completed'='GREEN_STATUS',
  'Last Signed'='GREEN_STATUS',
  'History'='GREEN_STATUS',
  'APPROVED' = 'GREEN_STATUS',
  'Archive'='GREEN_STATUS',
  'Obsolete'='RED_STATUS',
  'OBSOLETE'='RED_STATUS',
  'WIP' = 'WIP_STATUS',
  'Voided'='RED_STATUS',
  'CLOSED' = 'CLOSED_STATUS',
  'CANCELED' = 'CLOSED_STATUS',
  'Canceled' = 'CLOSED_STATUS',
  'VOIDED' = 'RED_STATUS',
  'Superseded'='GREEN_STATUS',
  'Void'='RED_STATUS',
  'IMPLEMENTATION'='GREEN_STATUS',
  'Implementation'='GREEN_STATUS',
}

export enum AlternatePartsHeaderStatusClassMapping {
  'Pending Approval'='PENDING_STATUS',
  'In Review'='PENDING_STATUS',
  'IN REVIEW' = 'PENDING_STATUS',
  'Pending Change'='PENDING_STATUS',
  'PENDING CHANGE'='PENDING_STATUS',
  'IN_REVIEW' = 'PENDING_STATUS',
  'Quarantine' = 'PENDING_STATUS',
  'Approved'='GREEN_STATUS',
  'Accepted'='GREEN_STATUS',
  'Released'='GREEN_STATUS',
  'RELEASED'='GREEN_STATUS',
  'Completed'='GREEN_STATUS',
  'History'='GREEN_STATUS',
  'APPROVED' = 'GREEN_STATUS',
  'Archive'='GREEN_STATUS',
  'Obsolete'='RED_STATUS',
  'OBSOLETE'='RED_STATUS',
  'VOIDED' = 'RED_STATUS',
  'Void'='RED_STATUS',
  'Voided'='RED_STATUS',
  'WIP' = 'WIP_STATUS',
  'Draft' = 'WIP_STATUS',
  'Last Signed'='WIP_STATUS',
  'IMPLEMENTATION'='WIP_STATUS',
  'Implementation'='WIP_STATUS',
  'Superseded'='GRAY_STATUS',
  'CLOSED' = 'GRAY_STATUS',
  'CANCELED' = 'GRAY_STATUS',
  'Canceled' = 'GRAY_STATUS',
}
