import { BoxProps } from '@material-ui/core';
import { cloneDeep, map } from 'lodash';
import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SM, SMBox, SMForm, SMProviderProps, SMTabs, SMTabsHOCProps, SMTemplate } from '../../../App';
import { withSMTabs } from '../../../App/Shifamed/Components/SMTabs/SMTabs.hoc';
import { ChangeRequest, ChangeRequestStatus } from '../../../state/ducks/changeRequest/types';
import { documentTypeSelectors } from '../../../state/ducks/documentRevisions/documentType';
import { TabInfo, TabTypeOptions } from '../../../state/ducks/documentRevisions/documentType/types';
import { ApplicationState } from '../../../state/reducers';
import { AsyncState } from '../../../state/types';
import { FBSchemaProps, FBWorkspace, FBWorkspaceModeOptions } from '../../form.builder';
import { FBWorkspaceMode } from '../../form.builder/types/workspace';
import Colors from '../../layout/theme/utils/colors';
import FBMaterialDisposition from '../FBMaterialDisposition';
import ChangeRequestSidebar from '../form/ChangeRequest.sidebar';
import { toChangeRequestFormValues } from '../form/transform';
import ItemsAndDetailsWrapper from '../items.and.details/ItemsAndDetails.wrap';
import ChangeSummaryFormHeader from './ChangeSummaryFormHeader';
import SummaryHeader from './SummaryHeader';

type ChangeRequestFormProps =
& Pick<SMProviderProps, '_formState' | '_documentRevisionFormState'>
& SMTabsHOCProps
& Pick<BoxProps, 'onScroll'>
& {
  asyncState: AsyncState
  changeRequest: ChangeRequest
  doNotPrompt?: boolean
  changeOpenStatus: (status: boolean) => void
  schema?: FBSchemaProps[]
  mode: FBWorkspaceMode
  withoutBottomBar: boolean
  autosave: boolean
  canEdit: boolean
  canChangeOwnership: boolean
  displayLastSaved: boolean
  errors: any
  hideSideBar?: boolean
};

const ChangeSummaryForm: React.FunctionComponent<ChangeRequestFormProps> = ({
  asyncState,
  changeRequest,
  doNotPrompt,
  changeOpenStatus,
  schema,
  mode,
  withoutBottomBar,
  autosave,
  _formState,
  canEdit,
  canChangeOwnership,
  displayLastSaved,
  errors,
  _documentRevisionFormState,
  hideSideBar = false,
}) => {
  const { _tabsState } = SM.useStores();
  _formState?.setMode(mode);

  const location = useLocation();
  const [isDrawerOpen, setOpen] = React.useState(true);
  const isAdditionalColumnsShown = [ChangeRequestStatus.Approved, ChangeRequestStatus.Closed].includes(changeRequest.state);

  const changeSidebarOpenStatus = (status) => {
    setOpen(status);
    changeOpenStatus(status);
  };

  const docTypeGroup = changeRequest.documentType.group ?? 'OTHER';
  const tabConfig = useSelector((state: ApplicationState) =>
    documentTypeSelectors.getTabConfiguration(state, docTypeGroup));

  if (tabConfig?.findIndex((tab: TabInfo) => tab.labelId === 'tabId.items') === -1) {
    tabConfig.splice(1, 0, {
      labelId: 'tabId.items',
      tabId: TabTypeOptions.ITEMS.toLowerCase(),
      type: TabTypeOptions.ITEMS,
    });
  }

  if (tabConfig?.findIndex((tab: TabInfo) => tab.labelId === 'tabId.materialDisposition') === -1) {
    tabConfig.splice(2, 0, {
      labelId: 'tabId.materialDisposition',
      tabId: TabTypeOptions.MATERIAL_DISPOSITION.toLowerCase(),
      type: TabTypeOptions.MATERIAL_DISPOSITION,
    });
  }

  useEffect(() => {
    const navigateToTabId = new URLSearchParams(location.search).get('tabId');
    if (navigateToTabId) {
      _tabsState?.setActiveTabId(navigateToTabId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <SMForm
      templateBoxProps={{ stretchY: '100%', width: '100%' }}
      contentBoxProps={{ bgcolor: Colors.alabaster }}
      values={toChangeRequestFormValues(changeRequest)}
      header= {
        <SummaryHeader {...{
          dataProp: changeRequest,
          canChangeOwnership,
          asyncState,
          errors,
          canEdit,
          displayLastSaved,
        }} />
      }
    >
      <SMTemplate
        boxProps={{ className: 'hide-scroll' }}
        templateBoxProps={{ stretchY: '100%' }}
        contentBoxProps={{ stretchY: '100%' }}
        rightSidebarBoxProps={{ zIndex: 2, marginBottom: 1 }}
        {...changeRequest && changeRequest.crId && !hideSideBar && {
          rightSidebar: (
            <Observer>
              {() => <ChangeRequestSidebar changeRequest={changeRequest} isDrawerOpen={isDrawerOpen}
                setOpen={changeSidebarOpenStatus} fromEdit tabConfig={tabConfig} />}
            </Observer>
          ),
        }}
      >
        <Observer>
          {() => (
            <SMTemplate
              templateBoxProps={{ stretchY: '100%' }}
              contentBoxProps={{
                overflow: _documentRevisionFormState?.expanded ? 'unset' : 'auto',
              }}
              header={<ChangeSummaryFormHeader
                title={changeRequest?.title}
                description = {changeRequest?.description}
                documentTypeId={changeRequest?.formDocument?.document?.documentType?.id}
                canEdit={canEdit}
                asyncState={asyncState}
              />}
            >
              <Observer>
                {() => (
                  <SMTabs
                    tabs={tabConfig}
                    boxProps={{ px: 3.75, stretchY: '100%', display: 'flex', flexDirection: 'column' }}
                    panelProps={{ flex: 1 }}
                  >
                    {map(tabConfig, (tabInfo: TabInfo, index: number) => {
                      const tabType = tabInfo.type;
                      const showTabContent = _tabsState?.isTabActive(tabInfo.tabId);

                      return (
                        <SMBox key={index} pt={0.25}>
                          {tabType === TabTypeOptions.MIXED && showTabContent && changeRequest
                            && <FBWorkspace
                              isPortal={false}
                              initialValues={cloneDeep(changeRequest?.formInput)}
                              changeRequest={cloneDeep(changeRequest)}
                              autosave={autosave}
                              withoutBottomBar={withoutBottomBar}
                              schema={schema}
                              mode={mode}
                            />
                          }
                          {tabType === TabTypeOptions.ITEMS
                            && _tabsState?.isTabActive(TabTypeOptions.ITEMS)
                            && changeRequest && (
                            <ItemsAndDetailsWrapper
                              changeRequest={cloneDeep(changeRequest)}
                              initialValues={cloneDeep(changeRequest?.formInput) ?? {}}
                              autosave={autosave}
                              mode={mode}
                              asyncState={asyncState}
                            />
                          )}
                          {tabType === TabTypeOptions.MATERIAL_DISPOSITION
                            && _tabsState?.isTabActive(TabTypeOptions.MATERIAL_DISPOSITION)
                            && <FBMaterialDisposition changeRequest={cloneDeep(changeRequest)} isShowOnly={true} isAdditionalColumnsShown={isAdditionalColumnsShown} />
                          }
                          {tabType === TabTypeOptions.DYNAMIC && showTabContent
                            && _formState?.mode !== FBWorkspaceModeOptions.NONE
                              && <FBWorkspace
                                isPortal={false}
                                initialValues={cloneDeep(changeRequest?.formInput)}
                                changeRequest={cloneDeep(changeRequest)}
                                autosave={autosave}
                                withoutBottomBar={withoutBottomBar}
                                schema={schema}
                                mode={mode}
                              />
                          }
                        </SMBox>
                      );
                    })}
                  </SMTabs>
                )}
              </Observer>
            </SMTemplate>
          )}
        </Observer>
      </SMTemplate>
    </SMForm>
  );
};

export default withSMTabs(ChangeSummaryForm);
