import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IotService } from '../../../common/utils/iot/iotService';
import { clearPOSavedInfo } from '../../../indexDb';
import { ResetPasswordValues } from '../../../ui/users/types';
import { apiAction, apiActionWithCallbacks } from '../../factories';
import { ApplicationState } from '../../reducers';
import { ApiAction, Handlers } from '../../types';
import { companyActions } from '../company';
import { Employee } from '../company/types';
import { userManagementActions } from '../userManagement';
import { AUTH_AWS_IOT_URL, AUTH_USER, CHANGE_PASSWORD_URL, CLEAR_NON_AUTH_DATA, CONFIRM_FORGOT_PASSWORD_URL, CONFIRM_SET_NEW_PASSWORD_URL, FORGOT_PASSWORD_URL, GROUPS_URL, LOGIN_URL, LOGIN_WITH_CODE_URL, LOGOUT_URL, LOGOUT_USER, PING_SERVER_GET_ASYNC, PING_SERVER_URL, RESEND_SIGNUP_EMAIL_URL, SEND_PASSWORD_RESET_EMAIL, SESSION_DETAILS_URL, SET_CREDENTIALS, SET_EMPLOYEE, SIGNUP_URL, SSO_CALLBACK_URL, TOGGLE_CUSTOM_LABELS, USERS_ME_URL, USERS_URL, USER_GROUPS, USER_GROUPS_GET_ASYNC } from './constants';
import { AuthResponse, AuthUserAction, ClearNonAuthDataAction, ConfirmPasswordAction, EditUserMeAction, EmployeeEditRequestBody, ForgotPassword, ForgotPasswordAction, ForgotPasswordConfirmation, Group, LoginRequestBody, LoginSSORequestBody, LoginWithCodeRequestBody, LogoutRequestBody, LogoutUserAction, NewPasswordConfirmation, SelectEmployeeAction, SetCredentialsAction, SignupAction, ToggleCustomLabelsAction, User, UserGroupsAction } from './types';

const authSignup = (): SignupAction => ({
  type: SIGNUP_URL,
});

const authForgotPassword = (): ForgotPasswordAction => ({
  type: FORGOT_PASSWORD_URL,
});

const authConfirmPassword = (): ConfirmPasswordAction => ({
  type: CONFIRM_FORGOT_PASSWORD_URL,
});

const authUser = (data: AuthResponse): AuthUserAction => ({
  type: AUTH_USER,
  payload: data,
});

const logoutUser = (): LogoutUserAction => ({
  type: LOGOUT_USER,
});

const userGroups = (groups: Group[]): UserGroupsAction => ({
  type: USER_GROUPS,
  payload: groups,
});

const editUser = (user: User): EditUserMeAction => ({
  type: USERS_ME_URL,
  payload: user,
});

const setCredentials = (iotCredentials: any): SetCredentialsAction => ({
  type: SET_CREDENTIALS,
  payload: iotCredentials,
});

const toggleCustomLabels = (customLabel: boolean): ToggleCustomLabelsAction => ({
  type: TOGGLE_CUSTOM_LABELS,
  payload: customLabel,
});

const editCurrentEmployee = (
  body: EmployeeEditRequestBody,
  handlers: Handlers,
): ApiAction<Partial<AuthResponse>> =>
  apiActionWithCallbacks({
    url: `${USERS_ME_URL}`,
    method: 'patch',
    data: body,
    onSuccess: (user, dispatch) => {
      dispatch(editUser(user));
    },
    handlers,
  });

const editSelectedEmployee = (
  id: string,
  body: EmployeeEditRequestBody,
  handlers: Handlers,
): ApiAction<Partial<AuthResponse>> =>
  apiActionWithCallbacks({
    url: `${USERS_URL}/${id}`,
    method: 'patch',
    data: body,
    onSuccess: (user, dispatch) => {
      dispatch(userManagementActions.editSelectedEmployeeUserInfo(user));
    },
    handlers,
  });

const onLoginSuccessCallback = (data: AuthResponse, dispatch) => {
  dispatch(authUser(data));
  const viableEmployees = (data.employees || []).filter((emp) => emp.active);
  if (viableEmployees.length === 1) {
    const employee = viableEmployees[0];

    dispatch(companyActions.selectCompany(employee.company));
    dispatch(selectEmployee(employee));
  }
};

const login = (
  loginValues: LoginRequestBody,
  handlers: Handlers,
): ApiAction<AuthResponse, LoginRequestBody> =>
  apiActionWithCallbacks({
    url: LOGIN_URL,
    method: 'post',
    data: loginValues,
    includeAuthorization: false,
    onSuccess: onLoginSuccessCallback,
    handlers,
  });

const loginSSO = (
  loginValues: LoginSSORequestBody,
  handlers: Handlers,
): ApiAction<AuthResponse> =>
  apiActionWithCallbacks({
    url: SSO_CALLBACK_URL,
    method: 'get',
    params: loginValues,
    includeAuthorization: false,
    onSuccess: onLoginSuccessCallback,
    handlers,
  });

const signup = (
  loginValues: LoginRequestBody,
  handlers: Handlers,
): ApiAction<AuthResponse, LoginRequestBody> =>
  apiActionWithCallbacks({
    url: SIGNUP_URL,
    method: 'post',
    data: loginValues,
    includeAuthorization: false,
    onSuccess: (m, dispatch) => {
      dispatch(authSignup());
    },
    handlers,
  });

const forgotPassword = (
  values: ForgotPassword,
  handlers: Handlers,
): ApiAction<AuthResponse, ForgotPassword> =>
  apiActionWithCallbacks({
    url: FORGOT_PASSWORD_URL,
    method: 'post',
    data: values,
    includeAuthorization: false,
    onSuccess: (m, dispatch) => {
      dispatch(authForgotPassword());
    },
    handlers,
  });

const confirmNewPassword = (
  confirmValues: ForgotPasswordConfirmation,
  handlers: Handlers,
): ApiAction<AuthResponse, ForgotPasswordConfirmation> =>
  apiActionWithCallbacks({
    url: CONFIRM_FORGOT_PASSWORD_URL,
    method: 'post',
    data: confirmValues,
    includeAuthorization: false,
    onSuccess: (m, dispatch) => {
      dispatch(authConfirmPassword());
    },
    handlers,
  });

const newUserSetPassword = (
  confirmValues: NewPasswordConfirmation,
  handlers: Handlers,
): ApiAction<AuthResponse, NewPasswordConfirmation> =>
  apiActionWithCallbacks({
    url: CONFIRM_SET_NEW_PASSWORD_URL,
    method: 'post',
    data: confirmValues,
    includeAuthorization: false,
    onSuccess: onLoginSuccessCallback,
    handlers,
  });

const resetPassword = (
  values: ResetPasswordValues,
  handlers: Handlers,
): ApiAction<AuthResponse, ResetPasswordValues> =>
  apiActionWithCallbacks({
    url: CHANGE_PASSWORD_URL,
    method: 'post',
    data: values,
    handlers,
  });

const loginWithCode = (
  loginValues: LoginWithCodeRequestBody,
  handlers: Handlers,
): ApiAction<AuthResponse, LoginWithCodeRequestBody> =>
  apiActionWithCallbacks({
    url: LOGIN_WITH_CODE_URL,
    method: 'post',
    data: loginValues,
    includeAuthorization: false,
    onSuccess: (response, dispatch) => {
      dispatch(authUser(response));
    },
    onFailure: (m, dispatch) => {
      dispatch(logoutUser());
    },
    handlers,
  });

const resendSignupEmail = (
  email: string,
  handlers: Handlers,
): ApiAction<Partial<AuthResponse>> =>
  apiActionWithCallbacks({
    url: RESEND_SIGNUP_EMAIL_URL,
    method: 'post',
    data: { email },
    handlers,
  });

const sendPasswordResetEmail = (
  employeeId: string,
  handlers: Handlers,
): ApiAction<Partial<AuthResponse>> =>
  apiActionWithCallbacks({
    url: SEND_PASSWORD_RESET_EMAIL,
    method: 'post',
    data: { employeeId },
    handlers,
  });

const logout = (): ThunkAction<void, ApplicationState, any, AnyAction> => (
  dispatch,
  getState,
) => {
  const action: ApiAction<any, LogoutRequestBody> = apiAction({
    url: LOGOUT_URL,
    method: 'delete',
    onCompleted: async () => {
      dispatch(logoutUser());
      const iotService = IotService.getInstance();
      iotService.unsubscribeAll();
      await clearPOSavedInfo();
    },
  });

  dispatch(action);
};

const loadUserGroups = (): ApiAction<Group[]> =>
  apiAction({
    url: `${GROUPS_URL}`,
    asyncType: USER_GROUPS_GET_ASYNC,
    method: 'get',
    onSuccess: (groups, dispatch) => {
      dispatch(userGroups(groups));
    },
  });

const getIotCreds = (): ApiAction =>
  apiAction({
    url: AUTH_AWS_IOT_URL,
    method: 'get',
    includeAuthorization: true,
    onSuccess: (iotCredentials, dispatch) => {
      dispatch(setCredentials(iotCredentials));
    },
  });

/** Helper */
export const setIotCreds = (iotCredentials) => {
  const iotService = IotService.getInstance();
  iotService.updateMqttClient(iotCredentials);
};

const pingServer = (): ApiAction =>
  apiAction({
    url: `${PING_SERVER_URL}`,
    asyncType: PING_SERVER_GET_ASYNC,
    method: 'get',
    onFailure: (message, dispatch) => {
      dispatch(logoutUser());
    },
  });

const selectEmployee = (employee: Employee): SelectEmployeeAction => ({
  type: SET_EMPLOYEE,
  payload: employee,
});

const getSessionDetails = (employeeId: string) =>
  apiAction({
    url: SESSION_DETAILS_URL,
    method: 'get',
    onSuccess: (data: any, dispatch) => {
      const employee = data.employees.find(
        (employee: any) => employee.id === employeeId,
      );
      const company = employee.company;
      dispatch(companyActions.selectCompany(company));
      dispatch(selectEmployee(employee));
    },
  });

const clearNonAuthData = (): ClearNonAuthDataAction => ({
  type: CLEAR_NON_AUTH_DATA,
});

export default {
  authUser,
  logoutUser,
  login,
  logout,
  loginSSO,
  loginWithCode,
  getIotCreds,
  loadUserGroups,
  pingServer,
  selectEmployee,
  editCurrentEmployee,
  editSelectedEmployee,
  signup,
  forgotPassword,
  confirmNewPassword,
  userGroups,
  editUser,
  getSessionDetails,
  newUserSetPassword,
  clearNonAuthData,
  resendSignupEmail,
  toggleCustomLabels,
  resetPassword,
  sendPasswordResetEmail,
  setCredentials,
};
