enum Colors {
  // Neutral
  navigationBg='#121B21',
  textBlack='#080B11',
  textGray='#767B87',
  textDisabled='#9A9FAB',
  buttonDisabled='#ABB0BA',
  stroke='#D3D5DB',
  hover='#EBEFF7',
  background='#F9FBFF',
  fieldDisabled='#EBEDF2',

  // Primary
  primary='#011FBB',
  primaryBg='#EDF3FF',
  primaryDark='#011994',
  primaryLight='#4D88FF',
  primaryGradient='#4C69FF',
  primaryHighlight='#F5F8FF',

  // Secondary
  secondary='#01A6C7',
  secondaryBg='#E6F6F9',
  secondaryLight='#E1E7F2',
  secondaryHighlight='#F2FBFC',
  secondaryDimmed='rgba(22, 34, 41, 0.15)',

  // Success
  success='#0DB78D',
  successHighlight='#F3FCF9',
  approve='#16bd23',
  successLight = '#B4FFEC',

  // Warning
  warning='#EFA10B',
  warningHighlight='#FEFAF3',
  warningStatusBorder='#CE8723',

  // Destructive/Error/Remove
  error='#EC2B00',
  errorHighlight='#FEF4F2',
  errorLightShadow='#FAE1DD',
  remove='#f05a5c',
  reject='#c40000',
  errorLight='#FFD9D1',

  // Shades
  white='#FFF',
  whiteDimmed='rgba(255, 255, 255, .5)',
  gray='#F9F9F9',
  darkGray='#F5F5F5',
  lightGray='#C8CACF',
  lightGray2='#C4C4C4',
  black='#000',

  // Statuses
  statusDraft='#4D88FF',
  statusDraftBg='#DCF4FD',
  statusDraftBorder='#457AB5',
  statusReview='#EFA10B',
  statusReleased='#0DB78D',
  statusVoid='#EC2B00',
  statusClosed='#A2A2A2',
  statusPendingChange='#FEAA2C',

  // Tables
  tableHeader='#F4F7FC',
  tableStroke='#DBE0EB',

  // Header
  headerBg='#1533CD',
}

export default Colors;
