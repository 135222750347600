import { pagerSettings } from '../../components/common/kendo/helpers';
import { KendoColumn, PageState } from '../../components/common/kendo/types';
import { DTMCellTemplates } from './config';

const columns: KendoColumn[] = [{
  field: 'id',
  title: 'common.id',
  show: false,
  showColumnMenu: false,
},
{
  field: 'active',
  title: 'common.status',
  filter: 'text',
  cell: DTMCellTemplates.ACTIVE_CELL,
  filterCell: DTMCellTemplates.DROPDOWN_FILTER_CELL,
  show: true,
  width: '150px',
  locked: true,
  orderIndex: 0,
  groupable: true,
  showColumnMenu: true,
},
{
  field: 'documentTypeName',
  title: 'common.document.type.name',
  show: true,
  width: '165px',
  locked: true,
  groupable: false,
  orderIndex: 1,
  showColumnMenu: false,
},
{
  field: 'documentTypeAcronym',
  title: 'common.document.type.acronym',
  show: true,
  width: '125px',
  locked: false,
  groupable: false,
  showColumnMenu: false,
  orderIndex: 2,
},
{
  field: 'privateNumberPool',
  title: 'common.private.number.pool',
  cell: DTMCellTemplates.CHECKBOX_CELL,
  filter: 'boolean',
  show: true,
  width: '110px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 3,
},
{
  field: 'canRedLine',
  title: 'common.redline',
  cell: DTMCellTemplates.CHECKBOX_CELL,
  filter: 'boolean',
  show: true,
  width: '110px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 4,
},
{
  field: 'isQms',
  title: 'common.qms',
  cell: DTMCellTemplates.CHECKBOX_CELL,
  filter: 'boolean',
  show: true,
  width: '110px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 5,
},
{
  field: 'needsSignature',
  title: 'form.builder.needsSignature',
  cell: DTMCellTemplates.CHECKBOX_CELL,
  filter: 'boolean',
  show: true,
  width: '110px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 6,
},
{
  field: 'defaultTraining',
  title: 'common.training',
  cell: DTMCellTemplates.CHECKBOX_CELL,
  filter: 'boolean',
  show: true,
  width: '110px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 7,
},
{
  field: 'startingNumber',
  title: 'common.starting.number',
  cell: DTMCellTemplates.INTEGER_NUMBER_CELL,
  filter: 'numeric',
  show: true,
  width: '185px',
  groupable: false,
  showColumnMenu: false,
  orderIndex: 8,
},
{
  field: 'numOfDocDigits',
  title: 'common.doc.digits',
  cell: DTMCellTemplates.DOC_DIGITS_CELL,
  filter: 'numeric',
  show: true,
  width: '120px',
  groupable: false,
  showColumnMenu: false,
  orderIndex: 9,
},
{
  field: 'defaultRevStage',
  title: 'document.revision.stage',
  cell: DTMCellTemplates.REVISION_STAGE,
  filterCell: DTMCellTemplates.DROPDOWN_FILTER_CELL,
  show: true,
  width: '165px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 10,
},
{
  field: 'hiddenForCreationForUsersWithoutTagsFormatted',
  title: 'common.hidden.message', // TODO: Check if multiSelect
  cell: DTMCellTemplates.REVISION_STAGE,
  filterCell: DTMCellTemplates.DROPDOWN_FILTER_CELL,
  show: true,
  width: '200px',
  groupable: false,
  showColumnMenu: false,
  orderIndex: 11,
},
{
  field: 'group',
  title: 'common.group',
  cell: DTMCellTemplates.REVISION_STAGE,
  filterCell: DTMCellTemplates.DROPDOWN_FILTER_CELL,
  show: true,
  width: '125px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 12,
}, {
  field: 'equipmentEnforceProd',
  title: 'common.equipmentEnforce',
  cell: DTMCellTemplates.CHECKBOX_CELL,
  filter: 'boolean',
  show: true,
  width: '125px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 13,
},
{
  field: 'category',
  title: 'form.builder.category', // In Wireframe its dd but not in existing. No datasource found
  show: true,
  width: '125px',
  groupable: true,
  showColumnMenu: true,
  orderIndex: 14,
}, {
  field: 'schema',
  title: 'common.schema',
  cell: DTMCellTemplates.SCHEMA,
  show: true,
  filterable: false,
  width: '110px',
  groupable: false,
  showColumnMenu: false,
  orderIndex: 15,
}, {
  field: 'fieldsConfig',
  title: 'common.fields.config',
  cell: DTMCellTemplates.FIELDS_CONFIG,
  show: true,
  filterable: false,
  width: '110px',
  groupable: false,
  showColumnMenu: false,
  orderIndex: 16,
}, {
  field: '',
  title: '',
  show: true,
  groupable: false,
  filterable: false,
  locked: true,
  width: '95px',
  reorderable: false,
  resizable: false,
  showColumnMenu: false,
  orderIndex: 17,
}];

const initialDataState: PageState = { skip: 0, take: 25, group: [] };
const gridConfiguration = {
  columns,
  pagerSettings,
  initialDataState,
};

export default gridConfiguration;
