import { createStyles, makeStyles } from '@material-ui/core';

export const styles = makeStyles(() =>
  createStyles({
    font: {
      fontSize: 12,
      fontWeight: 600,
    },
  }),
);
