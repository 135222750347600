import { FBEditorModeProps } from '..';

class FBEditorTitleState {
  public static modeConfig: FBEditorModeProps[] = [
    {
      mode: 'config',
      label: 'form.builder.field.config',
    },
    {
      mode: 'validation',
      label: 'form.builder.validation',
    },
  ];
}

export default FBEditorTitleState;
