import { Box } from '@material-ui/core';
import React from 'react';
import { FBAttachment, FBFilePresenter, FBFilesPresenterProps, FBMapWrapper } from '..';
import { withFBFilesPresenter } from './FBFilesPresenter.wrap';

const FBFilesPresenter: React.FunctionComponent<FBFilesPresenterProps> = ({
  attachments = [],
  boxProps,
  mode,
  disabled,
  name,
}) => (
  <Box { ...boxProps } display="flex" flexDirection="row" flexWrap="wrap">
    <FBMapWrapper<FBAttachment[]> collection={attachments}>
      {(file: FBAttachment, index) => (
        <FBFilePresenter key={`fb-file-presenter-${file.id}-${index}`} {...{ file, mode, disabled, name }} />
      )}
    </FBMapWrapper>
  </Box>
);

export default withFBFilesPresenter(FBFilesPresenter);
