import { FormikErrors } from 'formik';

export enum SQS_TYPES{
  TIME_TRAVEL_USER_SESSION = 'TIME_TRAVEL_USER_SESSION',
  TIME_TRAVEL_COMPANY ='TIME_TRAVEL_COMPANY',
  UPDATE_USERS_AND_TEST_PASSWORD_EXPIRE='UPDATE_USERS_AND_TEST_PASSWORD_EXPIRE',
  RECALC_COMPLIANCE_FOR_ON_EQ_RELEASE='RECALC_COMPLIANCE_FOR_ON_EQ_RELEASE',
  UNLOCK_USER_AND_SET_DEFAULT_PASSWORD='UNLOCK_USER_AND_SET_DEFAULT_PASSWORD',
  RECALC_COMPLIANCE_FOR_EQUIPMENTS='RECALC_COMPLIANCE_FOR_EQUIPMENTS',
  SEND_EMAILS_FOR_UPCOMING_DUE_DATES_IN_EQS='SEND_EMAILS_FOR_UPCOMING_DUE_DATES_IN_EQS',
  RECALC_GROUP_TO_EMPLOYEE_ALL_COMPANIES='RECALC_GROUP_TO_EMPLOYEE_ALL_COMPANIES',
  TRAINING_RECORDS_REFRESH='TRAINING_RECORDS_REFRESH',
  TRAINING_RECORDS_STATUS='TRAINING_RECORDS_STATUS',
  UPDATE_BILLS='UPDATE_BILLS',
  DELETE_COMPANIES_SCHDEULED_FOR_DELETE='DELETE_COMPANIES_SCHDEULED_FOR_DELETE',
  PRECALC_DOC_TYPE_FOR_COMPANY='PRECALC_DOC_TYPE_FOR_COMPANY',
  FIXER_RETRIEVE_CURRENCIES='FIXER_RETRIEVE_CURRENCIES',
  SEND_COMPANY_CONFIG_DIFF_TO_EMAILS='SEND_COMPANY_CONFIG_DIFF_TO_EMAILS',
  GENERATE_COMPANY_CONFIGS='GENERATE_COMPANY_CONFIGS',
  CLOSE_CR_BY_EFFECTIVE_DATE='CLOSE_CR_BY_EFFECTIVE_DATE',
  USAGE_REPORT='USAGE_REPORT',
  SEND_PASSWORD_EXPIRATION_EMAILS='SEND_PASSWORD_EXPIRATION_EMAILS',
  PRECALC_PAGE_DETAILS='PRECALC_PAGE_DETAILS',
  SELECT_ALL='Select All',
}

export interface TimeTravelProps {
  year: number
  month: number
  day: number
  hour: number
  minute: number
  second: number
  nextTaskTypes?: string[]
}

export const NEXT_TASK_TYPES = [
  'RECALC_COMPLIANCE_FOR_EQUIPMENTS',
  'RECALC_GROUP_TO_EMPLOYEE_ALL_COMPANIES',
  'TRAINING_RECORDS_REFRESH',
  'TRAINING_RECORDS_STATUS',
  'PRECALC_DOC_TYPE_FOR_COMPANY',
  'UPDATE_BILLS',
  'CLOSE_CR_BY_EFFECTIVE_DATE',
  'SEND_EMAILS_FOR_UPCOMING_DUE_DATES_IN_EQS',
];

export const NEXT_TASK_TYPES_USER = [
  'SEND_PASSWORD_EXPIRATION_EMAILS',
];

export interface AsyncProps {
  initialValues: TimeTravelProps
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  errors: FormikErrors<TimeTravelProps>
  type?: SQS_TYPES
}
