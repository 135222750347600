import { differenceBy, get, orderBy, unionBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FB } from '..';
import { SM } from '../../../App';
import { getHasPermission } from '../../../common/utils/selectors';
import { authSelectors } from '../../../state/ducks/auth';
import { Permission } from '../../../state/ducks/auth/types';
import { companySelectors } from '../../../state/ducks/company';
import { documentRevisionsActions, documentRevisionsSelectors } from '../../../state/ducks/documentRevisions';
import { DocumentRevision, DocumentRevisionStatus } from '../../../state/ducks/documentRevisions/types';
import { ApplicationState } from '../../../state/reducers';
import { getLatestRevisionId } from '../../document.revision/utils/helpers';
import useActionCreator from '../../hooks/useActionCreator';
import useAsync from '../../hooks/useAsync';
import { useOnHandQuantity } from '../FBLotTransfers/utils';
import FBStore from '../FBStore/FBStore';
import { CYCLE_COUNT_FREQUENCY_KEY, LOT_PART_SELECT_KEY } from './constants';
import { CycleCount, FBCycleCountProps } from './types';

const prepareCycleCounts = (cycleCounts: CycleCount[] = []) => orderBy(cycleCounts, 'date', 'desc');

export const withFBCycleCount = <T extends FBCycleCountProps>(
  Component: React.FC<T>,
): React.FC<T> => {
  const Comp = ({
    name = '',
    disabled,
    ...props
  }: T) => {
    const { formState, workspaceState } = FB.useStores();
    const { _formState } = SM.useStores();
    const dispatch = useDispatch();
    const employees = useSelector(companySelectors.getAllEmployees) ?? [];
    const currentEmployeeId = useSelector(authSelectors.currentEmployeeId);
    const currentUserId = useSelector(authSelectors.currentUserId);
    const canEditCycleCount = useSelector(getHasPermission(Permission.EDIT_CYCLE_COUNT));
    const documentRevisions = useSelector((state: ApplicationState) =>
      documentRevisionsSelectors.getDocumentRevisions(state, workspaceState?.document?.document.id ?? ''),
    );
    const { onHandQuantity, onHandQuantityUnit } = useOnHandQuantity();
    const [cycleCounts, setCycleCounts] = useState<CycleCount[]>(prepareCycleCounts(workspaceState?.formInputSync.get(name) ?? []));
    const [cycleCountFrequency, setCycleCountFrequency] = useState<number>();

    const lotPartRevisionId = workspaceState?.formInputSync.get(LOT_PART_SELECT_KEY) as string | undefined;
    const loadLotPartAction = useActionCreator(documentRevisionsActions.loadWithCallback);
    const async = useAsync<DocumentRevision>({
      onSuccess: (partDocRev) => {
        setCycleCountFrequency(get(partDocRev, ['formInput', CYCLE_COUNT_FREQUENCY_KEY]));
      },
    });

    useEffect(() => {
      if (lotPartRevisionId) {
        async.start(
          loadLotPartAction,
          lotPartRevisionId,
          async,
        );
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotPartRevisionId]);

    const { id, version, status } = workspaceState?.document ?? { version: 0, status: DocumentRevisionStatus.Voided };

    const isLatestRevision = id === getLatestRevisionId(documentRevisions);
    const isReleased = status === DocumentRevisionStatus.Released;
    const isVoided = [DocumentRevisionStatus.Voided, DocumentRevisionStatus.Obsolete].includes(status);
    const isQuarantined = status === DocumentRevisionStatus.InReview;

    const isOwner = workspaceState?.document?.owner?.user?.id === currentUserId;
    const canEditAsOwner = !disabled || (isOwner && isQuarantined);
    const isInEditableState = (version > 1 && !isVoided) || isReleased;
    const canEditWithPermission = canEditCycleCount && isInEditableState;

    const isEditable = id && isLatestRevision && (canEditAsOwner || canEditWithPermission);

    const updateState = (state: CycleCount[]) => {
      setCycleCounts(prepareCycleCounts(state));
      formState?.setFieldValue(name, state);
      formState?.setFieldAutosave(name);

      if (disabled && isEditable) {
        _formState?.setLoading(true);
        Promise.resolve(
          workspaceState?.updateDocRevById({ formInput: { [name]: state } }, workspaceState?.id),
        ).then(() => {
          workspaceState?.setFormInputSync(name, state);
          if (workspaceState?.id && FBStore?.isHistoryTabSelected) {
            dispatch(documentRevisionsActions.loadAudit(workspaceState?.id));
          }
        }).finally(() => {
          _formState?.setLoading(false);
        });
      }
    };

    const prepareCycleCount = (item: CycleCount) => ({
      ...item,
      systemCount: onHandQuantity,
      by: currentEmployeeId,
    });

    const deleteCycleCount = (item: CycleCount) => {
      const updatedData = differenceBy(cycleCounts, [item], 'id');
      updateState(updatedData);
    };

    const updateCycleCount = (item: CycleCount) => {
      const updatedData = unionBy([prepareCycleCount(item)], cycleCounts, 'id');
      updateState(updatedData);
    };

    return Component({
      ...props as T,
      name,
      disabled: !isEditable,
      cycleCounts,
      employees,
      onHandQuantity,
      onHandQuantityUnit,
      cycleCountFrequency,
      updateCycleCount,
      deleteCycleCount,
    });
  };

  return (props: T) => Comp(props);
};
