import { useFormikContext } from 'formik';
import { get, last, remove } from 'lodash';
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { FB, FBDialogState, FBInputType, FBPOReceiveCreateLot, FBPOReceiveCreateLotActions, FBPOReceiveForm, FBPOReceiveFormActions, FBPOReceiveItemProps, FBPOReceiveProps, FBPOReceiveState, FBPurchaseOrderItem, FBSchemaProps } from '..';
import { stripTags } from '../../../common/utils/helpers';
import { DOC_TYPE_GROUP } from '../../../state/ducks/documentRevisions/documentType/types';
import { DocumentRevision, DocumentRevisionStatus } from '../../../state/ducks/documentRevisions/types';
import { toastError } from '../../components/notifications';

export const withFBPOReceive = <T extends FBPOReceiveProps>(
  Component: React.FunctionComponent<T>,
) => {
  const Comp = ({
    poReceiveState,
    dialogState,
    getOptionLabel,
    onChange,
    initialValues,
    defaultValue,
    disabled,
    loading,
    schema,
    name,
    po,
    ...props
  }: T) => {
    const { workspaceState, formState } = FB.useStores();
    const formValue = formState?.getFieldValue(name);
    const intl = useIntl();
    const { formInput } = workspaceState || {};
    const { receivedItems } = name && (formInput?.[name] || { receivedItems: '' });
    const formik = useFormikContext();

    poReceiveState = FB.useRef<FBPOReceiveState>(FBPOReceiveState, formValue?.id);
    dialogState = FB.useRef<FBDialogState>(FBDialogState);
    defaultValue = formValue?.id;

    const handleItem = (item?: FBPurchaseOrderItem) => {
      if (workspaceState?.isReleased || workspaceState?.isRevised) {
        return;
      }
      if (!workspaceState?.documentId) {
        toastError(intl.formatMessage({ id: 'form.builder.save.doc.before.continue' }));
        return;
      }

      dialogState?.config({
        open: true,
        maxWidth: 'sm',
        title: 'form.builder.po.items.received',
        content: <FBPOReceiveForm {...{ item }} />,
        actions: <FBPOReceiveFormActions {...{ item }} />,
      });
    };

    const handleLot = (e: React.MouseEvent<HTMLButtonElement>, item?: FBPurchaseOrderItem) => {
      e.stopPropagation();
      if (item?.status && item?.status !== DocumentRevisionStatus.Released) {
        toastError(intl.formatMessage({ id: 'form.builder.po.create.a.lot.error' }));
      } else {
        dialogState?.config({
          open: true,
          maxWidth: 'sm',
          title: 'form.builder.po.create.a.lot',
          content: <FBPOReceiveCreateLot {...{ item }} />,
          actions: <FBPOReceiveCreateLotActions {...{ item }} />,
        });
      }
    };

    function setSchemaItem (item: FBSchemaProps): FBPOReceiveItemProps {
      return {
        ...item,
        disabled: true,
        ...(item.type === 'purchaseorder') && {
          mode: 'preview',
          handleItem,
          handleLot,
          receivableName: name,
        },
      };
    }

    function setSchemaProps (schema?: FBSchemaProps[]): FBSchemaProps[] | undefined {
      const id = workspaceState?.schema?.find((item) => item.type === 'poreceive')?.tabId;

      remove(schema || [], (item) => item?.type === 'poapprovals');
      if (!schema) { return; }
      const prior: Partial<Record<FBInputType | 'other', number>> = {
        purchaseorder: 1,
        other: 2,
      };
      schema.sort((a, b) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        prior[get(a, 'type', 'other')]! - prior[get(b, 'type', 'other')]!);

      schema = schema.reduce((reduced: FBSchemaProps[], item) => {
        if (item.name && !(/requestor|po-supplier-built-in|po-items-built-in/.test(item.name)
        && item.type !== 'section')) {
          return reduced;
        }

        if (workspaceState?.document?.document.documentType.group === DOC_TYPE_GROUP.RECEIVABLE) {
          reduced.push(setSchemaItem(item));
          return reduced;
        }

        if (last(reduced)?.type === 'purchaseorder' && item.type !== 'purchaseorder') {
          reduced.push({ type: 'section', label: 'form.builder.purchaseorder' });
        }

        reduced.push(setSchemaItem(item));
        return reduced;
      }, []);

      const modifiedTabIdSchema = schema.map((item) => ({
        ...item, tabId: id,
      }),

      );
      return modifiedTabIdSchema;
    }

    onChange = (_ev, value: DocumentRevision) => {
      poReceiveState?.setPOId(value?.id);
    };

    React.useEffect(() => {
      if (name && !workspaceState?.isRevised) {
        formState?.setFieldValue(name, { ...formInput?.[name] });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedItems]);

    React.useEffect(() => reaction(
      () => formState?.inputState.get(`porec-${name}`)?.value,
      (value) => {
        formState?.setFieldValue(name, { id: value });
        formState?.setFieldAutosave(name);
        formik?.submitForm();
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    getOptionLabel = (option: DocumentRevision) => `${option.document?.docId} ${stripTags(option.description)}`;

    useObserver(() => {
      loading = poReceiveState?.loading;
      schema = setSchemaProps(poReceiveState?.getSchema());
    });

    return Component({
      ...(props as T),
      poReceiveState,
      dialogState,
      getOptionLabel,
      onChange,
      initialValues: poReceiveState.docValues,
      defaultValue,
      disabled,
      loading,
      schema,
      name,
    });
  };

  return (props: T) => Comp(props);
};
