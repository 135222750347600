import { has, isEmpty, isEqual, omit } from 'lodash';
import { toJS } from 'mobx';

const clearMobxObject = (value) => typeof value === 'object'
  ? toJS(value)
  : value;

const getDiff = (transformedValues, transformedInitialValues) => {
  const diff = {};
  for (const [key, value] of Object.entries(transformedValues)) {
    if (key === 'formInput') {
      continue;
    }
    if (key === 'formDocument') {
      if ((value as any)?.id !== transformedInitialValues.formDocument?.id) {
        diff[key] = value;
      }
      continue;
    }
    if (key === 'formTemplate') {
      const initialFormTemplate = {
        outputDocumentTypes: (transformedInitialValues?.formTemplate?.outputDocumentTypes || [])
          .map((outputDoc) => ({ id: outputDoc.value })),
        schema: transformedInitialValues?.formTemplate?.schema,
      };
      const newFormTemplateValue = {
        outputDocumentTypes: (value as any).outputDocumentTypes,
        schema: toJS((value as any).schema),
      };
      if (!isEqual(initialFormTemplate, newFormTemplateValue)) {
        diff[key] = value;
      }
      continue;
    }
    if (!isEqual(value, transformedInitialValues[key])) {
      diff[key] = value;
    }
    if (key === 'referenceTo' && diff[key]) {
      diff[key] = diff[key]?.filter((referenceValue) => {
        if (isEmpty(referenceValue)) {
          return false;
        }
        return Boolean(
          Object.values(referenceValue).find((dataKeys) => !isEmpty(dataKeys)),
        );
      });
    }
  }
  return diff;
};

const getFormInputDiff = (transformedValues, transformedInitialValues) => {
  const formInputDiff = {};
  for (const [key, value] of Object.entries(transformedValues.formInput || {})) {
    const initialValueToCompare = clearMobxObject(transformedInitialValues.formInput?.[key]);
    const valueToCompare = clearMobxObject(value);
    if (!isEqual(valueToCompare, initialValueToCompare)) {
      formInputDiff[key] = valueToCompare;
    }
  }
  return formInputDiff;
};

export const compareValuesToSubmit = (initialValues, values, transformMethod) => {
  const transformedInitialValues = transformMethod(initialValues);
  const transformedValues = transformMethod(values);
  let diff = getDiff(transformedValues, transformedInitialValues);
  const formInputDiff = getFormInputDiff(transformedValues, transformedInitialValues);

  // Added in case maintenance is created outside of EQ Maintenance
  const equipmentId = initialValues?.parentEquipment?.id;
  if (!isEmpty(equipmentId)) {
    diff = {
      ...diff,
      parentEquipment: {
        id: equipmentId,
      },
    };
  }

  const hasKeysNotInValuesFormInput = transformedInitialValues?.formInput
    ? Object.keys(transformedInitialValues.formInput).some((k) => !(k in transformedValues.formInput))
    : false;

  if (isEmpty(formInputDiff) && !hasKeysNotInValuesFormInput) {
    return diff;
  }

  const updatedFormInput = has(transformedValues.formInput, 'formInput')
    ? transformedValues.formInput.formInput : transformedValues.formInput;

  // TEMPORARY FIX FOR BE VALIDATION
  // formInput: transformedValues.formInput,
  const formInputToSave = omit(
    updatedFormInput,
    ['partType', 'childForRevise'],
  );

  return {
    ...diff,
    ...(!isEmpty(formInputToSave) ? { formInput: formInputToSave } : {}),
  };
};
