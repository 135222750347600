import { isEmpty } from 'lodash';
import { useObserver } from 'mobx-react';
import React from 'react';
import { FB, FBMediaAlbumProps, FBMediaAlbumState, FBWorkspaceModeOptions } from '..';

export const withFBMediaAlbum = <T extends FBMediaAlbumProps>(
  Component: React.FunctionComponent<T>,
) => {
  const Comp = ({
    onClick,
    setMediaMode,
    mediaAlbumState,
    collection = [],
    hiddenContainer,
    actionsDisabled,
    title = '',
    mode,
    name,
    uploadDisabled,
    ...props
  }: T) => {
    mediaAlbumState = FB.useRef<FBMediaAlbumState>(FBMediaAlbumState);
    const { formState, workspaceState } = FB.useStores();
    const { mode: workspaceMode, id } = workspaceState || {};

    // Handle mode
    setMediaMode = () => {
      const mode = mediaAlbumState?.mode === 'carousel' ? 'gallery' : 'carousel';
      mediaAlbumState?.setMode(mode);
    };

    useObserver(() => {
      mode = mediaAlbumState?.mode;
      uploadDisabled = formState?.locked.get(name || '')?.locked;
      title = (mode === 'carousel')
        ? collection[mediaAlbumState?.activeMedia || 0]?.name
        : '';
      hiddenContainer = Boolean((workspaceMode === FBWorkspaceModeOptions.PREVIEW
        || workspaceMode === FBWorkspaceModeOptions.FORMPREVIEW || actionsDisabled)
         && isEmpty(collection),
      );
    });

    onClick = () => {
      if (formState?.isBackdropOpen) { return; }
      if (!name) { return; }
      if (!id) { return; }
      formState?.lockField({
        fields: [{
          formInput: [{
            [name]: true,
          }],
        }],
        documentRevisionId: id,
      }, name);
    };

    const fileUploadEl = document.getElementById(`fb-mediaalbum-upload-${name}`);

    if (fileUploadEl) {
      fileUploadEl.onclick = registerCancel;
    }

    function registerCancel () {
      document.body.onfocus = removeLock;
    }

    function removeLock () {
      if (fileUploadEl?.nodeValue?.length) { return; }
      formState?.unlockField(name);
    }

    return Component({
      ...(props as T),
      onClick,
      setMediaMode,
      mediaAlbumState,
      formState,
      workspaceMode,
      hiddenContainer,
      actionsDisabled,
      collection,
      title,
      mode,
      name,
      uploadDisabled,
    });
  };

  return (props: T) => Comp(props);
};
