import { FBApprovalMatrixFieldState, FBButtonProps, FBInputProps } from '..';
import { FBWorkspaceMode } from '../types/workspace';

export type FBApprovalMatrixFieldProps =
& Pick<FBInputProps, 'name' | 'disabled' | 'autosave' | 'type'>
& {
  handleAdd?: FBButtonProps['onClick']
  approvalMatrixFieldState?: FBApprovalMatrixFieldState
  mode?: FBApprovalMatrixFieldModes
  workspaceMode: FBWorkspaceMode
};

export interface FBApprovalRole {
  id: string
  employees: Array<{ id: string }>
  groups: Array<{ id: string }>
  name: string
  index: number
}

interface FBApprovalMatrixDocType {
  updatedCategory?: string
  id?: string
  acronym: string
  name: string
  defaultTraining: boolean
  group: string
  needsSignature: boolean
  category: string
  active?: boolean
}

export interface FBApprovalRolesStages {
  stage1: boolean[]
  stage2: boolean[]
  stage3: boolean[]
  admStage: boolean[]
}

export interface FBApprovalMatrixItem {
  id: string
  docType: FBApprovalMatrixDocType
  approvalRoles: FBApprovalRolesStages
}

export interface FBApprovalMatrixFieldData {
  approvalRoles: FBApprovalRole[]
  approvalMatrix: FBApprovalMatrixItem[]
}

export enum FBApprovalMatrixFieldModes {
  NONE = 'none',
  ADD = 'add',
  EDIT = 'edit',
  FULLSCREEN = 'fullscreen',
  REFRESH = 'refresh',
}

export interface FBApprovalMatrixConstructor {
  formValue?: FBApprovalMatrixFieldData
  outputRevision?: boolean
  isOutput?: boolean
}
