import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  previewTabContent: {
    paddingBottom: theme.spacing(1.5),
  },
  previewTabPanel: {
    marginRight: theme.spacing(-2),
  },
}));
