import { isEmpty, isEqual, isNumber } from 'lodash';
import * as Yup from 'yup';
import { Translation } from '../../../../translations/types';

const reactQuillEmptyString = '<p><br></p>';

export type Validator = (value: any) => Translation | undefined;

export const required: Validator = (value) => {
  if (value === reactQuillEmptyString || isEmpty(value)) {
    return 'validators.required';
  }
};

export const email: Validator = (value: string) => {
  // https://stackoverflow.com/a/34096956
  // eslint-disable-next-line max-len
  if (!/^[a-z0-9!'#$%&*+/=?^_`{|}~-]+(?:\.[a-z0-9!'#$%&*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-zA-Z]{2,}$/i.test(value)) {
    return 'validators.email';
  }
};

export const requiredNumber: Validator = (value) => {
  if (!isNumber(value)) {
    return 'validators.required';
  }
};

export const positiveNumber: Validator = (value) => {
  if (value && (isNaN(parseFloat(value)) || parseFloat(value) < 0)) {
    return 'validators.positive.number';
  }
};

export const confirmResetPasswordSchema = Yup.string()
  .oneOf([Yup.ref('newPassword'), null], 'validator.match.passwords')
  .required('validator.confirm');

export const requiredSchema = Yup.string()
  .required('validators.required');

const validatorFactory = (...validators: Validator[]): Validator => (value: unknown): Translation | undefined => {
  for (const validatorFn of validators) {
    const error = validatorFn(value);

    if (error !== undefined) {
      return error;
    }
  }
};

export const validator = (() => {
  const memo: Array<{
    validators: Validator[]
    finalValidator: Validator
  }> = [];

  return (...validators: Validator[]): Validator => {
    const memoValidator = memo.find((el) => isEqual(el.validators, validators));

    if (memoValidator) {
      return memoValidator.finalValidator;
    }

    const newValidator = {
      validators,
      finalValidator: validatorFactory(...validators),
    };

    memo.push(newValidator);

    return newValidator.finalValidator;
  };
})();

export const timeTravelValidator = Yup.object().shape({
  year: Yup.string()
    .required('async.task.filed.error')
    .matches(/^[0-9]+$/),
  month: Yup.string()
    .required('async.task.filed.error')
    .matches(/^[0-9]+$/),
  day: Yup.string()
    .required('async.task.filed.error')
    .matches(/^[0-9]+$/),
  hour: Yup.string()
    .required('async.task.filed.error')
    .matches(/^[0-9]+$/),
  minute: Yup.string()
    .required('async.task.filed.error')
    .matches(/^[0-9]+$/),
  second: Yup.string()
    .required('async.task.filed.error')
    .matches(/^[0-9]+$/),
});
