import { isEmpty, some } from 'lodash';
import { Document, DocumentRevision, DocumentRevisionStatus } from '../../../state/ducks/documentRevisions/types';

export const getIsLastVersionObsolete = (formDocuments: Document) =>
  some(formDocuments.documentRevisions, ['status', DocumentRevisionStatus.Obsolete]);

export const isDocumentRevisionInDraft = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Draft], documentRevisionStatus);
export const isDocumentRevisionApproved = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Approved], documentRevisionStatus);
export const isDocumentRevisionVoided = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Voided], documentRevisionStatus);
export const isDocumentRevisionInReview = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.InReview], documentRevisionStatus);
export const isDocumentRevisionCanceled = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Canceled], documentRevisionStatus);
export const isDocumentRevisionDeprecated = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Deprecated], documentRevisionStatus);
export const isDocumentRevisionObsolete = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Obsolete], documentRevisionStatus);
export const isDocumentRevisionPendingChange = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.PendingChange], documentRevisionStatus);
export const isDocumentRevisionReleased = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Released], documentRevisionStatus);
export const isDocumentRevisionInStatusFlag = (documentRevisionStatus) => isDocumentRevisionInStatus([DocumentRevisionStatus.Released, DocumentRevisionStatus.InReview, DocumentRevisionStatus.Approved], documentRevisionStatus);

/** Checks if a document revision is in a specific status.
   * @param documentRevisionStatus Status of a document.
   * @param statuses Array of statuses to check.
   */
export const isDocumentRevisionInStatus = (
  statuses: DocumentRevisionStatus[],
  documentRevisionStatus?: DocumentRevisionStatus,
) => {
  if (!documentRevisionStatus) { return false; }
  return statuses.includes(documentRevisionStatus);
};

/** Check if a document revision has output.
   * @param documentRevision Document Revision.
   */
export const isDocumentRevisionHasOutput = (
  documentRevision: DocumentRevision,
) => !isEmpty(documentRevision?.formDocument?.id);
