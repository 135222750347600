import { useFormikContext } from 'formik';
import React from 'react';
import { MODE_FIELD, Mode } from '../../../components/KendoDataGrid/constants';
import OverflowTooltip from '../../../components/OverflowTooltip';
import { FormikField, TextField } from '../../../components/forms/fields-next';
import { requiredNumber, validator } from '../../../components/forms/fields/validators';
import { DEDUCTABLE_TRANSFER_TYPES } from '../constants';
import { CustomTemplateProps, LotTransferType } from '../types';

export const QuantityCellTemplate: React.FC<CustomTemplateProps> = ({ dataItem, field, onClick }) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getFieldProps } = useFormikContext();
  const isEditMode = [Mode.add, Mode.edit].includes(dataItem[MODE_FIELD]);
  const defaultValue = dataItem.quantity;

  const handleClick = () => {
    onClick?.({ dataItem });
  };

  if (isEditMode) {
    return (
      <FormikField
        name={field}
        validate={validator(requiredNumber)}
      >
        <TextField
          {...getFieldProps(field)}
          defaultValue={undefined}
          type="number"
          inputProps={{ min: 0 }}
          size="small"
        />
      </FormikField>
    );
  }

  return (
    <OverflowTooltip
      title={defaultValue}
      onClick={handleClick}
      placement="top"
      interactive
      arrow
    >
      {DEDUCTABLE_TRANSFER_TYPES.includes(dataItem?.type)
        ? (dataItem?.type === LotTransferType.Backflush ? defaultValue : `-${defaultValue}`)
        : defaultValue
      }
    </OverflowTooltip>
  );
};
