import { BoxProps, FormControl, FormHelperText, Typography } from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import { FieldProps as FormikFieldProps } from 'formik';
import { get } from 'lodash';
import * as React from 'react';
import { TypographyProps, TypographySize } from '../../../../state/ducks/common/types';
import { Translation } from '../../../translations/types';
import HeaderField from '../../common/header.field/field';
import Text from '../../Text';
import { styles } from './Field.styles';
import { LabelField } from './Input.styles';

export interface FieldOwnProps {
  label?: Translation | string
  formControlProps?: FormControlProps
  inputLabelProps?: InputLabelProps
  variant?: 'standard' | 'outlined' | 'filled'
  required?: boolean
  gutter?: boolean
  minDate?: string
  value?: string
  actionsRenderer?: () => any
  labelProps?: BoxProps
  inputLabel?: Translation
}

export interface FieldStyleProps {
  gutter?: boolean
  error?: boolean
}

type FieldProps = FieldOwnProps & FormikFieldProps & Partial<TypographyProps>;

const Field: React.FunctionComponent<FieldProps> = ({
  field,
  form,
  form: { touched, errors, isSubmitting },
  children,
  formControlProps,
  label,
  variant,
  required,
  size = 'md',
  gutter = true,
  actionsRenderer,
  labelProps,
  inputLabel,
}) => {
  // storing BE response in status
  const error = get(errors, field.name);
  const isFieldTouched = get(touched, field.name);
  const shouldDisplayError = Boolean(isFieldTouched && error);
  const classes = styles({ gutter, error: shouldDisplayError });

  // Workaround for untouched select fields
  if (error && !shouldDisplayError && isSubmitting) {
    form.setFieldTouched(field.name, true);
  }

  const InputLabelComponent = () => {
    if (!inputLabel) return null;

    return (
      <InputLabel>
        <Text translation={inputLabel} />
      </InputLabel>
    );
  };

  const LabelComponent = () => {
    if (actionsRenderer) {
      return (
        <HeaderField dataProp={label || ''} actionsRenderer={actionsRenderer} />
      );
    }
    return (
      label && (
        <LabelField htmlFor={field.name} style={{ position: 'inherit' }}>
          <Typography
            variant={TypographySize[size]}
            className={classes.typography}
            style={labelProps}
          >
            <Text message={label} />
          </Typography>
        </LabelField>
      )
    );
  };

  const ErrorComponent = shouldDisplayError && (
    <FormHelperText>
      <Text message={String(error)} />
    </FormHelperText>
  );

  return (
    <FormControl
      id={`field-${field.name}`}
      variant={variant || 'standard'}
      required={required}
      fullWidth={true}
      error={shouldDisplayError}
      className={inputLabel ? classes.formInputLabel : classes.form}
      style={labelProps}
      data-cy={`field-${field.name}`}
      {...formControlProps}
    >
      {LabelComponent()}
      {InputLabelComponent()}
      {children}
      {ErrorComponent}
    </FormControl>
  );
};

export default Field;
