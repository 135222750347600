import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
    justifyContent: 'space-between',
    gap: theme.spacing(1.25, 1.25),
  },
}));
