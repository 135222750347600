export const attachmentColorByType = {
  PDF: '#BD0100',
  DOCX: '#3A68AF',
  XLS: '#178F3C',
  ZIP: '#9D7120',
  GIF: '#00477D',
  JPG: '#389FED',
  RTF: '#9187C4',
  AVI: '#63B17B',
  CSS: '#6A5CAF',
  BIN: '#3BA8FA',
  MPEG: '#157A80',
  HTML: '#29D2DB',
  SVG: '#EFAB2F',
  RAR: '#620553',
  PNG: '#3BA8FA',
  MOV: '#CF3D00',
  PPT: '#FF822E',
  CSV: '#FF822E',
  TXT: '#1A8187',
  DWT: '#77B75A',
  WAV: '#08551F',
  XD: '#6F0A58',
};
