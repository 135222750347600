import Colors from '../../layout/theme/utils/colors';

export const FBFontStyle = {
  font: {
  },
  labelProps: {
    fontWeight: 600,
    fontSize: 12,
    color: Colors.almost_black,
    lineHeight: '15px',
  },
  labelMediumProps: {
    fontWeight: 600,
    fontSize: 14,
    color: Colors.almost_black,
    lineHeight: '15px',
  },
};
