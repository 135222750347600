export const ATTACHMENT_ROOT_URL = '/attachments';
export const PURCHASE_ORDER_ROOT_URL = '/purchase_order';
export const ATTACHMENT_UPLOAD_URL = `${ATTACHMENT_ROOT_URL}/upload`;
export const ATTACHMENT_PUBLIC_UPLOAD_URL = `${ATTACHMENT_ROOT_URL}/public_upload_url`;
export const REQUEST_WATERMARK_ASYNC = 'attachments/REQUEST_WATERMARK_ASYNC';
export const CHECK_WATERMARK_ASYNC = 'attachments/CHECK_WATERMARK_ASYNC';
export const ADD_WATERMARK = 'attachments/ADD_WATERMARK';
export const REMOVE_WATERMARK = 'attachments/REMOVE_WATERMARK';
export const CLEAR_WATERMARKS = 'attachments/CLEAR_WATERMARKS';
export const LINE_TYPES = {
  CLEAN_COPY: 'CLEAN_COPY',
  RED_LINE: 'RED_LINE',
};
