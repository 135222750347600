export const DOCUMENT_TYPE_LIST = 'documentType/LIST';

export const DOCUMENT_TYPE_LIST_ASYNC = 'documentType/LIST_ASYNC';

export const DOCUMENT_TYPE_BY_ID = 'documentType/ID';

export const DOCUMENT_TYPE_BY_ID_ASYNC = 'documentType/ID_ASYNC';

export const DOCUMENT_TYPE_ROOT_URL = '/document_type';

export const DOCUMENT_TYPE_BY_GROUP_ASYNC = 'documentType/GROUP_ASYNC';
export const DOCUMENT_TYPE_BY_CATEGORY_ASYNC = 'documentType/CATEGORY_ASYNC';
export const SET_DOCUMENT_TYPES_BY_CATEGORY = 'documentType/SET_CATEGORY';

export const TAB_CONFIGURATION_URL = `${DOCUMENT_TYPE_ROOT_URL}/tab_configuration_for_groups`;
export const TAB_CONFIGURATION_LIST_ASYNC = 'tabConfiguration/LIST_ASYNC';
export const TAB_CONFIGURATION_LIST = 'tabConfiguration/LIST';
