import { Observer } from 'mobx-react';
import React from 'react';
import { SMBox, SMTemplateProps } from '../../..';

const SMTemplate: React.FunctionComponent<SMTemplateProps> = ({
  banner,
  header,
  lhrSteps,
  templateBoxProps,
  headerBoxProps,
  footerBoxProps,
  leftSidebarBoxProps,
  rightSidebarBoxProps,
  contentBoxProps,
  leftSidebar,
  rightSidebar,
  boxProps,
  footer,
  children,
}) =>
  (
    <Observer>{() =>
      <SMBox display="flex" flexDirection="row" flex={1} {...templateBoxProps}>
        {leftSidebar && (
          <SMBox {...leftSidebarBoxProps}>
            {leftSidebar}
          </SMBox>
        )}
        <SMBox display="flex" flexDirection="column" flex={5} {...boxProps}>
          {banner && (
            <SMBox {...headerBoxProps}>
              {banner}
            </SMBox>
          )}
          {header && (
            <SMBox {...headerBoxProps}>
              {header}
            </SMBox>
          )}
          {lhrSteps && (
            <SMBox {...headerBoxProps}>
              {lhrSteps}
            </SMBox>
          )}
          <SMBox
            display="flex"
            overflow="auto"
            flex={1}
            {...contentBoxProps}
          >
            <SMBox width={0} flex={1}>
              {children}
            </SMBox>
          </SMBox>
          {footer && (
            <SMBox {...footerBoxProps}>
              {footer}
            </SMBox>
          )}
        </SMBox>
        {rightSidebar && (
          <SMBox {...rightSidebarBoxProps} flex={0}>
            {rightSidebar}
          </SMBox>
        )}
      </SMBox>
    }</Observer>
  );

export default SMTemplate;
