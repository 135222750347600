import { Box } from '@material-ui/core';
import { cloneDeep, compact, concat, difference, filter, find, includes, isEmpty, last, map, some, sortBy, union } from 'lodash';
import { reaction } from 'mobx';
import { useObserver } from 'mobx-react';
import React, { useMemo } from 'react';
import { FB, FBApprover, FBApproversProps, FBApproversState, FBDocumentRevisionsValue, FBJoinedEmployee, FBOption, FBRequiredApprover } from '..';
import { ChangeRequestStatus } from '../../../state/ducks/changeRequest/types';
import { Approval, ApprovalStatus } from '../../../state/ducks/common/types';
import { useFormContext } from '../../components/forms/FormContext';
import { checkIsDocumentPO } from '../../documentRevision/helpers/checkDocumentGroup';
import Colors from '../../layout/theme/utils/colors';

export const withFBApprovers = <T extends FBApproversProps>(
  Component: React.FC<T>,
): React.FC<T> => {
  const Comp = ({
    renderOption,
    isIncluded,
    onBlur,
    approversState,
    name = '',
    loading,
    value,
    groups,
    checkedGroups,
    defaultValue = [],
    disabled,
    approvalApprovedGroup,
    ...props
  }: T) => {
    const { formState, workspaceState } = FB.useStores();
    const { changeRequest } = workspaceState || {};
    const { approvals } = changeRequest || {};
    const { submitForm } = useFormContext();
    const schema = workspaceState?.getSchema();
    const { name: docRevName } = useMemo(() => find(schema, { type: 'documentRevisions' }) ?? {}, [schema]);
    const docRevFormValue = formState?.getFieldValue(docRevName);
    const documentRevisions = useMemo(() => map(docRevFormValue, 'proposedDocumentRevision.id'), [docRevFormValue]);
    const isARforPO = some(
      workspaceState?.changeRequest?.documentRevisions,
      (revision) => checkIsDocumentPO(revision.document?.documentType?.groupOptions),
    );
    // ENC-6598 - app.approver for new structure, app for old structure
    defaultValue = formState?.getFieldValue(name)?.map((app) => app.approver || app);
    const formValue = map(defaultValue, 'id');
    approversState = FB.useRef(FBApproversState, { documentRevisions, formValue });

    onBlur = () => submitForm();

    const changeRequestApprovals = workspaceState?.changeRequest?.approvals.reduce<Approval[]>((list, approval) => {
      const isExistsInApprovers = formValue.includes(approval.approver.id);
      return isExistsInApprovers ? [
        ...list,
        approval,
      ] : list;
    }, []);

    React.useEffect(() => {
      reaction( // React on documentRevisions comp changes
        () => formState?.inputState.get(docRevName ?? '')?.value,
        (value) => getRequiredApprovers(cloneDeep(value)),
      );

      reaction( // React on approvers autocomplete changes
        () => formState?.inputState.get(`fb-approvers-${name}` || '')?.value,
        (value) => {
          markGroupChecked(value);

          const approversWithSignatures = value?.map((approver) => ({
            approver: { id: approver.id || null },
            signatureGroupId: {
              id: find(approversState?.checkedGroups, (group) => group.groupApprover === approver.id)?.id || null,
            },
          })) || [];
          formState?.setFieldValue(name, approversWithSignatures);
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      reaction(
        () => approversState?.data,
        (response) => resetToInitialGroups(response),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetToInitialGroups = (requiredGroups) => {
      const initialApprovers = workspaceState?.changeRequest?.formInput?.[name];
      const initialIds = initialApprovers?.map((app) => app.signatureGroupId.id);
      const initialGroups = requiredGroups?.reduce((acc, group) => {
        if (includes(initialIds, group.id)) {
          acc.push({
            ...group,
            groupApprover: find(initialApprovers, (app) => app.signatureGroupId.id === group.id).approver.id,
          });
        }
        return acc;
      }, []);
      const initialGroupsDAM = filter(requiredGroups, (group) =>
        map((group.joinedEmployees as FBJoinedEmployee[]), 'id').some((employee) =>
          initialApprovers?.map((e) => e.approver.id).includes(employee)));

      if (isARforPO) {
        approversState?.setCheckedGroups(initialGroups || []);
        return;
      }
      approversState?.setCheckedGroups(initialGroupsDAM || []);
    };

    function getRequiredApprovers (documentRevisions: FBDocumentRevisionsValue[]) {
      const ids: string[] = map(documentRevisions, 'proposedDocumentRevision.id');
      approversState?.fetchRequiredApprovers(ids);
    }

    function includesApprover (group: FBRequiredApprover, approver: string): boolean {
      return (group.joinedEmployees as string[]).includes(approver);
    }

    // eslint-disable-next-line react/display-name
    renderOption = (option: FBApprover) => {
      const requiredGroups: string []
        = compact(map(groups, (group) => includesApprover(group, option.id) ? group.name : ''));
      return (
        <Box display="flex" key={`fb-required-approver-${option.id}`}>
          <b>{option.user?.name}</b>&nbsp; ({option.user?.email})
          {!isEmpty(requiredGroups) && (
            <Box color={Colors.font_gray}>
              &nbsp;{`- ${requiredGroups.join(', ')}`}
            </Box>
          )}
        </Box>
      );
    };

    isIncluded = (group: FBRequiredApprover): boolean => (
      includes(map(approversState?.checkedGroups, 'id'), group.id));

    /** Check/uncheck DAM/POAM required approval groups based on autocomplete selection
   * @selectedUsers Current autocomplete selection.
   */
    const markGroupChecked = (selectedUsers: FBOption[]) => {
      const previousSelectionIds = approversState?.value || [];
      const newSelectionIds = map(selectedUsers, 'id');
      const requiredGroups = sortBy(approversState?.data || [], 'documentStage');
      if (isARforPO) { // one user can fullfil only one signature requirement (POAM)
        if (!isEmpty(difference(newSelectionIds, previousSelectionIds))) { // user added
          const newUserId = last(difference(newSelectionIds, previousSelectionIds));
          const checkedGroup
            = find(requiredGroups, (group) =>
              includes(map((group.joinedEmployees as FBJoinedEmployee[]), 'id'), newUserId)
              && !includes(approversState?.checkedGroups?.map((g) => g.id), group.id)) as FBRequiredApprover;
          if (!checkedGroup) {
            approversState?.setValue(newSelectionIds);
            return;
          }
          checkedGroup.groupApprover = newUserId;
          const newGroups = concat(approversState?.checkedGroups || [], checkedGroup);
          approversState?.setCheckedGroups(newGroups || []);
        } else if (!isEmpty(difference(previousSelectionIds, newSelectionIds))) { // user removed
          const removedUserId = last(difference(previousSelectionIds, newSelectionIds));
          const newGroups = approversState?.checkedGroups?.filter((group) => group.groupApprover !== removedUserId);
          approversState?.setCheckedGroups(newGroups || []);
        }
      } else { // user can fullfil all available signature requirements (DAM)
        const newGroups = filter(requiredGroups, (group) =>
          map((group.joinedEmployees as FBJoinedEmployee[]), 'id').some((employee) =>
            newSelectionIds?.includes(employee)));
        approversState?.setCheckedGroups(newGroups);
      }
      approversState?.setValue(newSelectionIds);
    };

    useObserver(() => {
      value = approversState?.value;
      groups = approversState?.groups;
      loading = approversState?.loading;
      checkedGroups = approversState?.checkedGroups;
      disabled = !workspaceState?.changeRequest;
    });

    const approvedStates = [
      ChangeRequestStatus.Closed,
      ChangeRequestStatus.Approved,
    ];
    const isApproved = changeRequest?.state && approvedStates.includes(changeRequest?.state);

    if (isApproved) {
      if (approvals?.length) {
        approvalApprovedGroup = approvals.reduce((list, approval) => {
          if (approval.status !== ApprovalStatus.Approved) {
            return list;
          }
          return union(list, approval.approverGroups);
        }, []);
      } else {
        groups = {};
      }
    }

    return Component({
      ...(props as T),
      renderOption,
      isIncluded,
      onBlur,
      loading,
      name,
      value,
      groups,
      defaultValue,
      disabled,
      changeRequestApprovals,
      approvalApprovedGroup,
    });
  };

  Comp.displayName = 'withFBApprovers';
  return Comp;
};
