export const SUB_ITEMS_FIELD = 'lotEntries';
export const ID_FIELD = 'customDocId';
export const DOC_ID_FIELD = 'docId';
export const EXCEL_FILE_NAME = 'allocationTree.xlsx';
export const DATA_ITEM_KEY = 'id';
export const EXPAND_FIELD = 'expanded';
export const MODE_FIELD = 'inEdit';
export const ADD_FIELD = 'isNew';
export const CELL_EMPTY_VALUE = '-';
export const MAX_DECIMALS = 4;
export const TREELIST_CONFIG = {
  sortable: false,
  reorderable: false,
  resizable: true,
  scrollable: 'virtual',
  rowHeight: 50,
};

export enum AllocationCheckBoxOptions {
  AUTO_ASSIGN = 'autoAssign',
  AUTO_TRANSACT = 'autoTransact',
}

export const ALLOCATION_TABLE_NAME = 'ALLOCATION_TABLE';
