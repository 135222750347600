import { Button, Divider, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import cx from 'classnames';
import { isEmpty, map, some } from 'lodash';
import { useObserver } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChangeRequest } from '../../../state/ducks/changeRequest/types';
import { documentRevisionsSelectors } from '../../../state/ducks/documentRevisions';
import { ApplicationState } from '../../../state/reducers';
import { useFormContext } from '../../components/forms/FormContext';
import Text from '../../components/Text';
import { FBFormState } from '../../form.builder';
import ApprovalRequestEditGridItem from './ApprovalRequestEditGridItem';
import useStyles from './ApprovalRequestGrid.styles';
import ApprovalRequestGridHeader from './ApprovalRequestGridHeader';
import ApprovalRequestItem from './ApprovalRequestItem';
import ItemsAndDetailsState from './ItemsAndDetails.state';
import { ApprovalRequestGridItem, FBDocumentRevisionsValue } from './ItemsAndDetails.types';

const Loader = () => {
  const classes = useStyles();

  return (
    <div
      data-cy="ar-items-loader"
      className={classes.loader}
    >
      <Text translation="common.loading" />
    </div>
  );
};

const TopSection: React.FunctionComponent<{
  isButtonShown?: boolean
  showEditRow: () => void
}> = ({
  isButtonShown = true,
  showEditRow,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Grid className={classes.gridCenterAlign} container>
        <Grid item>
          <Typography variant="h6" id="sectionTitle" component="div" className={classes.sectionTitle}>
            <Text translation="approvalRequest.items.section.heading" />
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            <Button
              data-cy="approvalRequest.items.button.addText"
              className={classes.btnMargin}
              disabled={!isButtonShown}
              color="primary"
              startIcon={<AddCircleIcon color={!isButtonShown ? 'disabled' : 'primary'} />}
              onClick={isButtonShown ? showEditRow : (e) => e.preventDefault()}
            >
              <Text translation="approvalRequest.items.button.addText" />
            </Button>
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.dividerStyle} />
    </div>
  );
};

const ItemsAndDetails: React.FunctionComponent<{
  changeRequest?: ChangeRequest
  formState: FBFormState
  handleAdd: () => void
  handleRemove: (docRevId: string) => void
  handleUpdate: () => void
  itemsAndDetailsState: ItemsAndDetailsState
}> = ({
  changeRequest,
  formState,
  handleAdd,
  handleRemove,
  handleUpdate,
  itemsAndDetailsState,
}) => {
  const classes = useStyles();
  const [isEditRowVisible, setIsEditRowVisible] = useState(false);
  const { isEditing } = useFormContext();

  const documentRevision = useSelector(
    (state: ApplicationState) => documentRevisionsSelectors.getDocumentRevision(state, itemsAndDetailsState.approvalRequstItemInEditMode),
  );

  useEffect(() => {
    if (itemsAndDetailsState.approvalRequstItemInEditMode !== '') {
      formState.setFieldValue('proposedDocumentRevision', { id: itemsAndDetailsState.approvalRequstItemInEditMode });
      showEditRow();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsAndDetailsState.approvalRequstItemInEditMode]);

  const showEditRow = () => {
    setIsEditRowVisible(true);
    itemsAndDetailsState.setViewItemActionsVisibility(false);
    if (itemsAndDetailsState.approvalRequstItemInEditMode === '') {
      removePrePopulatedField();
    }
  };

  function disabledOptions (): string[] {
    return map(itemsAndDetailsState?.approvalRequestItemsList, 'proposedDocumentRevision.id');
  }

  function resetAndCancelEdit () {
    // remove values for formState for keys
    removePrePopulatedField();

    setIsEditRowVisible(false);
    itemsAndDetailsState.setViewItemActionsVisibility(true);
  }

  const removePrePopulatedField = () => {
    const removeKeys = ['proposedDocumentRevision', 'descriptionOfChange', 'justificationOfChange', 'autoUpdate'];
    removeKeys.forEach((item) => {
      formState.setFieldValue(item, undefined);
    });
  };

  function addRevisionToChangeRequest () {
    const { proposedDocumentRevision, justificationOfChange, descriptionOfChange }
      = formState?.getValues() as FBDocumentRevisionsValue;

    if (some([justificationOfChange, descriptionOfChange, proposedDocumentRevision], isEmpty)) {
      return;
    }

    setIsEditRowVisible(false);
    handleAdd();
  }

  return useObserver(() => (
    <div className={cx(classes.tabContent, {
      [classes.tabContentInactive]: itemsAndDetailsState.loadingState,
    })}>
      { itemsAndDetailsState.loadingState && <Loader /> }
      <TopSection showEditRow={showEditRow} isButtonShown={itemsAndDetailsState.viewItemActionsVisible && !isEditing} />
      <ApprovalRequestGridHeader isLineItemPartCanBeSubstituted={changeRequest?.isLineItemPartCanBeSubstituted} />
      { isEditRowVisible && <ApprovalRequestEditGridItem
        {...{ formState }}
        disabledOptions={disabledOptions()}
        addRevisionToChangeRequest={addRevisionToChangeRequest}
        resetAndCancelEdit={resetAndCancelEdit}
        isLineItemPartCanBeSubstituted={changeRequest?.isLineItemPartCanBeSubstituted}
        documentRevision={documentRevision}
      />}
      {
        itemsAndDetailsState.approvalRequestItemsList.map((entry: ApprovalRequestGridItem) => (
          <ApprovalRequestItem
            key={`fb-document-revision-${entry.id}`}
            entry={entry}
            revision= {documentRevision?.revision}
            {...{ formState }}
            disabledOptions={disabledOptions}
            handleRemove = {handleRemove}
            handleUpdate = {handleUpdate}
            viewItemActionsVisible = {itemsAndDetailsState.viewItemActionsVisible}
            itemsAndDetailsState = {itemsAndDetailsState}
            isLineItemPartCanBeSubstituted={changeRequest?.isLineItemPartCanBeSubstituted}
            isDeletionDisabled={isEditing}
          />
        ))
      }
    </div>
  ));
};

export default ItemsAndDetails;
