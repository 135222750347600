enum Colors {
  // white
  white = '#FFF',
  background_white = '#F5F8FF',
  alabaster = '#F9FBFF',
  lily_white='#F8F9F9',
  water='#EEF3FF',
  titan_white='#EBEFF7',
  zircon = '#F2F9FF',
  // black
  black = '#000',
  almost_black = '#080B11', // font_black
  // gray
  hint_gray = '#b4c0ca',
  light_gray = '#F2F4F6',
  light_gray_II= '#4d4d4d',
  light_gray_III= '#424242',
  medium_gray = '#D4DBE1',
  gray = '#8A9DAD',
  dark_gray = '#474747',
  platinum_gray = '#e3e3e3',
  font_gray = '#767B87',
  medium_light_shade_gray = 'A2A2A2',
  mirage='#121B21',
  bright_grey = '#32424E',
  lightest_grey = 'rgba(0, 0, 0, 0.12)',
  grey_cloud = '#B4B4B4',
  gray_goose ='#CCD4D9',
  raven = '#767B87',
  whitest_grey='#EBEDF2',
  lightest_grey1='#CCD4D9',
  lightest_grey2= '#9A9FAB',
  athens_gray= '#F5F6F8',
  link_water = '#ccd4d9',
  platinum_grey = '#E2E2E2',
  cultured='#f6f7f7',
  light_athens_gray ='#E9EBF0',
  bright_gray2='#E1E7F2',
  dark_gray2='#ABB0BA',
  // blue
  light_blue = '#edf4ff',
  light_blue2 = '#EDF3FF',
  blue = '#0066FF',
  medium_blue = '#5C9DFC',
  background_blue = '#DBE9FF',
  navy = '#011FBB',
  navy_active = '#011994',
  sky_blue = '#F5F8FF',
  latest_blue_varient = '#3080EA',
  bright_blue='#0075FF',
  aqua_blue = '#20D5E0',
  light_gray_blue = '#CBD5E0',
  cerulean='#01A6C7',
  cerulean_light_shade='rgba(1, 166, 199, 0.3)',
  light_slate_blue='#7B61FF',
  steel_blue='#457AB5',
  dodger_blue='#4D88FF',
  ultramarine_blue='#4C69FF',
  mild_wind_blue='#CCD4D9',
  topaz='#03C0C1',
  clear_blue='#2684FF',
  light_cream_blue = '#EEF1F9',
  alice_blue='#e2e8f0',
  deep_blue='#033D5A',
  blue_bell='#979EC71A',
  // red
  red = '#c40000',
  dark_red = '#a10808',
  light_red = '#FCDEDE',
  error_red = '#F44336',
  medium_red = '#FF8183',
  opal_red = '#f05a5c',
  latest_red_varient = '#EC2B00',
  trinary_red = '#F14822',
  mandy = '#EB4770',
  dawn='#A2A2A2',
  seashell='#FCF0ED',
  errorHighlight='#FEF4F2',

  // purple
  deep_purple = '#29064C',
  purple = '#99c',
  // green
  light_green = '#BED948',
  green = '#16bd23',
  pale_green = '#f0f7d2',
  latest_green_varient = '#0DB78D',
  olivine = '#9EB774',
  snow_flurry='#E6FCD9',
  light_forest_green='#4C9646',
  green_blue='#0DB78D',
  light_forest_green_border='#509849',
  dark_jungle_green='#162229',
  // yellow
  light_yellow = '#FFF3CD',
  dark_yellow = '#856404',
  latest_yellow_varient = '#FEAA2C',
  pink_red = '#f50057',
  school_bus_yellow = '#EFA20B',
  light_yellow_warning = '#EFA10B',
  // orange
  basket_ball_orange = '#F88961',
  old_lace='#FEF8EE',
  tawny='#B25F00',
  ochre='#CE8723',
  blaze_orange='#FF6500',
  // cyan
  light_cyan='#DCF4FD',
  lochmara='#306AAC',
  peri_winkle_blue='#8295FA',
  bright_gray='#EBEDF2',
  light_gray_border='#CCD4D9',
  darkPeach='#B24736',
  // Platinum
  platinum='#E5E5E5',
  chinese_silver='#C8CACF',
  spanish_gray='#999999',
  gains_boro='#DDDDDD',
  white_smoke='#F9F9F9',
  gradient_color= 'rgba(255, 255, 255, 0.1)',
}

export default Colors;
