import { Box } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, { ChangeEvent } from 'react';
import { EMPTY_VALUE_PLACEHOLDER, MODE_FIELD, Mode } from '../../../components/KendoDataGrid/constants';
import OverflowTooltip from '../../../components/OverflowTooltip';
import { Autocomplete, FormikField } from '../../../components/forms/fields-next';
import { CompanyLocation, CustomTemplateProps, EditableLotTransfer, LotTransferType } from '../types';

interface Props extends CustomTemplateProps {
  options: CompanyLocation[]
  allowedOptions: string[]
  isAclEnabled: boolean
}

export const LocationCellTemplate: React.FC<Props> = ({ dataItem, field, options, isAclEnabled, allowedOptions, onClick }) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { values, getFieldProps, setFieldValue } = useFormikContext<EditableLotTransfer>();
  const isEditMode = [Mode.add, Mode.edit].includes(dataItem[MODE_FIELD]);

  const handleChange = (event: ChangeEvent<unknown>, value?: CompanyLocation) => {
    setFieldValue(field, value?.id);
  };

  const defaultValue = (isEditMode ? values[field] : dataItem[field]) as string;
  const value = options.find((loc) => loc.id === defaultValue);

  const isScrapAction = values.type === LotTransferType.Scrap;
  const isDisabled = field === 'to' && isScrapAction;

  // TODO recheck isEmpty condition after upgrading MUI Autocomplete
  if (isEditMode && !isEmpty(values)) {
    return (
      <FormikField
        name={field}
        required={field === 'from'}
      >
        <Autocomplete
          {...getFieldProps(field)}
          blurOnSelect
          options={options.filter(loc => loc.active && (!isAclEnabled || allowedOptions.includes(loc.id)))}
          getOptionLabel={(option: CompanyLocation) => option.name}
          value={isDisabled ? null : value}
          disabled={isDisabled}
          onChange={handleChange}
          size="small"
        />
      </FormikField>
    );
  }

  const displayValue = value ? value.name : EMPTY_VALUE_PLACEHOLDER;

  return (
    <Box onClick={() => onClick?.({ dataItem })}>
      <OverflowTooltip
        title={displayValue}
        placement="top"

        interactive
        arrow
      >
        {displayValue}
      </OverflowTooltip>
    </Box>
  );
};
