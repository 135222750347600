export const DOCUMENTS_LIST = 'documents/LIST';
export const DOCUMENTS_TO_REVIEW_LIST = 'documents/REVIEW_LIST';
export const DOCUMENT_SET = 'documents/SET';

export const DOCUMENTS_LIST_ASYNC = 'documents/LIST_ASYNC';
export const DOCUMENTS_TO_REVIEW_LIST_ASYNC = 'documents/REVIEW_LIST_ASYNC';
export const DOCUMENT_GET_ASYNC = 'documents/GET_ASYNC';

export const DOCUMENTS_ROOT_URL = '/documents';
export const DOCUMENTS_TO_REVIEW_URL = `${DOCUMENTS_ROOT_URL}/pending_review`;

export const DOCUMENT_PROPOSED_DOC_ID = `${DOCUMENTS_ROOT_URL}/proposed_docid`;
export const DOCUMENT_PROPOSED_DOC_ID_ASYNC = 'documents/PROPOSED_DOC_ID_ASYNC';
export const DOCUMENT_PROPOSED_DOC_ID_SET = 'documents/PROPOSED_DOC_ID_SET';
