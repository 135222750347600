import { isEmpty, isEqual, isUndefined, size } from 'lodash';
import { useObserver } from 'mobx-react';
import React, { useEffect } from 'react';
import { FB, FBAttachment, FBMediaCarouselProps, FBMediaCarouselState } from '..';

export const withFBMediaCarousel = <T extends FBMediaCarouselProps>(
  Component: React.FunctionComponent<T>,
) => {
  const Comp = ({
    removeMedia,
    onMouseOver,
    onMouseLeave,
    handleDelete,
    handleNext,
    handleBack,
    collection = [],
    mediaCarouselState,
    activeMediaPath = '',
    toolsVisibility,
    activeStep,
    previewType,
    autosave,
    ...props
  }: T) => {
    mediaCarouselState = FB.useRef<FBMediaCarouselState>(FBMediaCarouselState);
    const { mediaAlbumState, workspaceState, formState } = FB.useStores();

    useEffect(() => {
      if (isUndefined(mediaAlbumState?.activeMedia)) {
        mediaAlbumState?.setActiveMedia(0);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setPreviewType () {
      previewType = mediaAlbumState?.previewType(collection[activeStep || 0]);
    }

    handleNext = () => {
      if (isUndefined(mediaAlbumState)) {
        return;
      }
      let nextStep = (mediaAlbumState?.activeMedia || 0) + 1;
      isEqual(nextStep, size(collection)) && (nextStep = 0);
      mediaAlbumState?.setActiveMedia(nextStep);
    };

    handleBack = () => {
      if (isUndefined(mediaAlbumState)) {
        return;
      }
      let prevStep = (mediaAlbumState?.activeMedia || size(collection)) - 1;
      prevStep < 0 && (prevStep = size(collection) - 1);
      mediaAlbumState?.setActiveMedia(prevStep);
    };

    handleDelete = () => {
      const activeMedia = mediaAlbumState?.activeMedia;
      if (isUndefined(activeMedia)) {
        return;
      }
      const media: FBAttachment | undefined = collection[activeMedia];
      if (isUndefined(media)) {
        return;
      }
      removeMedia && removeMedia(media.id);
      mediaAlbumState?.setActiveMedia(0);
      if (!workspaceState?.autosave) { return; }
      workspaceState?.saveDocRev(formState?.getValues());
    };

    onMouseOver = () => !isEmpty(activeMediaPath) && mediaCarouselState?.setToolsVisible(true);
    onMouseLeave = () => mediaCarouselState?.setToolsVisible(false);

    useObserver(() => {
      activeStep = mediaAlbumState?.activeMedia || 0;
      activeMediaPath = collection[activeStep]?.url || '';
      toolsVisibility = mediaCarouselState?.toolsVisibility || 'hidden';
      setPreviewType();
    });

    return Component({
      ...(props as T),
      onMouseOver,
      onMouseLeave,
      handleNext,
      handleBack,
      handleDelete,
      activeMediaPath,
      toolsVisibility,
      collection,
      activeStep,
      previewType,
      autosave,
    });
  };

  return (props: T) => Comp(props);
};
