import { MY_FEEDS_LIST } from './constants';

export enum ActionTypes {
  APPROVAL_REQ_OWNER = 'APPROVAL_REQ_OWNER',
  APPROVAL_REQ_APPROVER = 'APPROVAL_REQ_APPROVER',
  DIRECT_APPROVAL_REQ_APPROVER = 'DIRECT_APPROVAL_REQ_APPROVER',
  DIRECT_APPROVAL_REQ_OWNER = 'DIRECT_APPROVAL_REQ_OWNER',
  APPROVAL_REQ_INLINE_APPROVER = 'APPROVAL_REQ_INLINE_APPROVER',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_DOC_CONTROL = 'PENDING_DOC_CONTROL',
  PENDING_TRAINING_RECORDS = 'PENDING_TRAINING_RECORDS',
  PURCHASE_ORDERS = 'PURCHASE_ORDERS',
  APPROVED_APPROVAL_REQUESTS = 'APPROVED_APPROVAL_REQUESTS',
  NEW_TRAINING_RECORDS = 'NEW_TRAINING_RECORDS',
  DIRECT_PO_APPROVAL_REQ_APPROVER = 'DIRECT_PO_APPROVAL_REQ_APPROVER',
  DIRECT_PO_APPROVAL_REQ_OWNER = 'DIRECT_PO_APPROVAL_REQ_OWNER',
  DIRECT_PO_APPROVAL_REQ_OWNER_APPROVER_APPROVED = 'DIRECT_PO_APPROVAL_REQ_OWNER_APPROVER_APPROVED',
  DIRECT_APPROVAL_REQ_OWNER_APPROVER_APPROVED_OR_REJECTED = 'DIRECT_APPROVAL_REQ_OWNER_APPROVER_APPROVED_OR_REJECTED',
  APPROVAL_REQ_OWNER_APPROVER_APPROVED_OR_REJECTED = 'APPROVAL_REQ_OWNER_APPROVER_APPROVED_OR_REJECTED',
  EQ_CALIBRATION_OWNER_OR_COORDINATOR = 'EQ_CALIBRATION_OWNER_OR_COORDINATOR',
  EQ_MAINTENANCE_OWNER_OR_COORDINATOR = 'EQ_MAINTENANCE_OWNER_OR_COORDINATOR',
  TRAINING_STATUS_TRAINEE = 'TRAINING_STATUS_TRAINEE',
  DISPOSITION_TASKS_ASSIGNED = 'DISPOSITION_TASKS_ASSIGNED',
  WORK_ORDER_TASKS_ASSIGNED = 'WORK_ORDER_TASKS_ASSIGNED',
}

export enum TrainingStatus {
  OVERDUE = 'OVERDUE',
  DUE_IN_7_DAYS = 'DUE IN 7 DAYS',
  ASSIGNED = 'ASSIGNED',
  DUE = 'DUE',
  COMPLETED = 'COMPLETED',
  PENDING = 'Pending',
}
export enum TrainingStatusLabels {
  OVERDUE = 'OVERDUE',
  DUE = 'DUE IN 7 DAYS',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  PENDING = 'Pending',
}

export enum LINE_ITEMS_STATUS_TYPES {
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}

export enum STATUS_TYPES_LABEL {
  IN_REVIEW = 'IN REVIEW',
  APPROVED = 'APPROVED',
  RELEASED = 'RELEASED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
  WIP = 'WIP',
  VOIDED = 'VOIDED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
  IMPLEMENTATION = 'IMPLEMENTATION',
  PENDING_CHANGE = 'PENDING CHANGE',
  PENDING_APPROVAL = 'PENDING APPROVAL',
  OBSOLETE = 'OBSOLETE',
  CURRENT = 'CURRENT',
  CANCELED = 'CANCELED',
  ARCHIVE = 'ARCHIVE',
  LAST_SIGNED = 'LAST SIGNED',
  SUPERSEDED = 'SUPERSEDED',
  ACCEPTED = 'ACCEPTED',
  VOID = 'VOID',
  EDIT = 'EDIT',
  IN_PROGRESS = 'IN PROGRESS',
}

export interface MyFeedsItems {
  accessedAt: string
  itemType: string
  itemId: string
  docId: string
  docTitle: string
  docType: string
  docRevId?: string
  timestamp: string
}

export interface MyFeedsListAction {
  type: typeof MY_FEEDS_LIST
  payload: MyFeedsListApiResponse
  initialLoad?: boolean
}

export interface MyFeedsListApiResponse {
  results: MyFeedsItems[]
  page_total: number
  total: number
}
export type MyFeedsState = MyFeedsListApiResponse;
