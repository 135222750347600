import { find } from 'lodash';
import { reaction } from 'mobx';
import React, { useEffect } from 'react';
import { FB, FBAutocompleteAsyncOption, FBPartsSelectionProps, FBWorkspaceModeOptions } from '..';
import { DocumentRevision, DocumentRevisionStatus } from '../../../state/ducks/documentRevisions/types';
import { checkIsDocumentLHR, checkIsDocumentLHRT } from '../../documentRevision/helpers/checkDocumentGroup';
import FBAutocompleteAsyncStore from '../FBAutocompleteAsync/FBAutocompleteAsync.store';

export const withFBPartsSelection = <T extends FBPartsSelectionProps>(
  Component: React.FunctionComponent<T>,
) => {
  const Comp = ({
    isOutput,
    disabled,
    defaultValue,
    editorConfig,
    index,
    filteredOptions,
    isDocumentLHR,
    lhrSelectedPartValue,
    name = '',
    ...props
  }: T) => {
    // MARK: @config
    const { workspaceState, formState } = FB.useStores();
    isOutput = workspaceState?.isOutput;
    const { documentType } = workspaceState?.document?.document || {};
    isDocumentLHR = checkIsDocumentLHR(documentType?.groupOptions);
    const isDocumentLHRT = checkIsDocumentLHRT(documentType?.groupOptions);
    const { PREVIEW, FORMPREVIEW } = FBWorkspaceModeOptions;

    const mode = workspaceState?.mode;
    const isPreviewForLHR = Boolean(isDocumentLHR || (workspaceState?.isPreview && isDocumentLHRT));
    const isPreviewMode = mode === PREVIEW || mode === FORMPREVIEW;
    disabled = isPreviewMode || isPreviewForLHR;

    if (!isOutput) {
      defaultValue = editorConfig?.id;
    }

    lhrSelectedPartValue = formState?.initialValues ? formState?.initialValues.name : '';

    // MARK: @reactions
    useEffect(() => reaction(
      () => formState?.inputState.get(name || '')?.value,
      (value) => {
        if (isOutput) {
          return;
        }

        const schemeItem = workspaceState?.getSchemaItemAt(index);
        workspaceState?.setSchemaItem({
          ...schemeItem,
          editorConfig: { id: value },
        });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    useEffect(() => reaction(
      () => FBAutocompleteAsyncStore.data.get(FBAutocompleteAsyncOption.docRevByDoc),
      (docRevs: DocumentRevision[]) => {
        if (!workspaceState?.document?.formInput?.[name]) {
          const released = find(Array.from(docRevs.values()), (rev) => rev.status === 'RELEASED');
          if (!released) { return; }
          formState?.setFieldValue(name, (released).id);
          formState?.getInputState(name || '')?.setRender();
          workspaceState?.saveDocRev(formState?.getValues());
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    // MARK: @helpers
    function handleFilter (
      data: DocumentRevision[],
    ) {
      const filteredData = data.filter((e) => e.status === DocumentRevisionStatus.Released);
      return filteredData;
    }

    // MARK: @methods
    filteredOptions = (options) =>
      handleFilter(options);

    // MARK: @methods

    // MARK: @observer
    return Component({
      ...(props as T),
      isOutput,
      disabled,
      defaultValue,
      editorConfig,
      index,
      name,
      filteredOptions,
      isDocumentLHR,
      lhrSelectedPartValue,
    });
  };

  Comp.displayName = 'withFBPartsSelection';
  return Comp;
};
