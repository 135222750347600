import { Attachment } from '../attachments/types';
import { ChangeRequest } from '../changeRequest/types';
import { Approval } from '../common/types';
import { DocumentRevision } from '../documentRevisions/types';
import { TrainingRecord } from '../training/types';
import { PENDING_ACTIONS_LIST, PENDING_ACTIONS_REMOVE } from './constants';

export enum PendingActionType {
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_DOC_CONTROL = 'PENDING_DOC_CONTROL',
  PENDING_TASK = 'PENDING_TASK',
  PENDING_TRAINING_RECORDS = 'PENDING_TRAINING_RECORDS',
  PURCHASE_ORDERS = 'PURCHASE_ORDERS',
  APPROVED_APPROVAL_REQUESTS = 'APPROVED_APPROVAL_REQUESTS',
}

export enum ActionTypes {
  PENDING_REVIEW = 'PENDING_REVIEW',
  PENDING_DOC_CONTROL = 'PENDING_DOC_CONTROL',
  PENDING_TASK = 'PENDING_TASK',
  PENDING_TRAINING_RECORDS = 'PENDING_TRAINING_RECORDS',
  PURCHASE_ORDERS = 'PURCHASE_ORDERS',
  APPROVED_APPROVAL_REQUESTS = 'APPROVED_APPROVAL_REQUESTS',
  NEW_TRAINING_RECORDS = 'NEW_TRAINING_RECORDS',
  TRAINING_STATUS_OWNER = 'TRAINING_STATUS_OWNER',
  TRAINING_STATUS_TRAINEE = 'TRAINING_STATUS_TRAINEE',
}

export enum TrainingStatus {
  OVERDUE = 'OVERDUE',
  DUE_IN_7_DAYS = 'DUE IN 7 DAYS',
}

export enum TransitionTypes {
  TRANSITION_CLOSED = 'closed',
  TRANSITION_REJECT = 'reject',
}

export interface PendingAction {
  type: PendingActionType
  userId: string
  changeReq?: ChangeRequest
  trainingRecord?: TrainingRecord
  documentRevision?: DocumentRevision
  docRev?: DocumentRevision
  approval?: Approval
  docRevAttachments?: Attachment[]
}

export interface PendingActionsListAction {
  type: typeof PENDING_ACTIONS_LIST
  payload: PendingAction[]
}

export interface PendingActionsRemoveAction {
  type: typeof PENDING_ACTIONS_REMOVE
  payload: number
}

export type PendingActionsState = PendingAction[];
