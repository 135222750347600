import { Box, CircularProgress } from '@material-ui/core';
import { map, pick } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { attachmentsActions } from '../../../../state/ducks/attachments';
import { LINE_TYPES } from '../../../../state/ducks/attachments/constants';
import { Attachment } from '../../../../state/ducks/attachments/types';
import FBDynamicValidatorStore from '../../../form.builder/stores/ui/FBDynamicValidatorStore';
import FBStore from '../../../form.builder/stores/ui/FBStore';
import useActionCreator from '../../../hooks/useActionCreator';
import useAsync from '../../../hooks/useAsync';
import { useFormContext } from '../../forms/FormContext';
import { withFormik } from '../../hoc/formik';
import { toastError } from '../../notifications';
import Presenter from './presenter';
import { FILE_UPLOAD_LIMIT, calculateTotalFileSize, handleFileSizeExceedLimit } from './utils';
import { AttachmentContainerProps } from './utils/types';

const AttachmentFieldContainer: React.FunctionComponent<AttachmentContainerProps> = ({
  name = '',
  upload,
  component = 'button',
  multiple = true,
  field,
  form,
  ...other
}) => {
  const { submitForm } = useFormContext();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const intl = useIntl();

  const uploadAction = useActionCreator(attachmentsActions.upload);

  const async = useAsync<Attachment>({
    onSuccess: (attachment?: Attachment) => {
      setIsUploading(false);
      if (!attachment && !form && !field) {
        return;
      }
      if (!multiple) {
        return form.setFieldValue(name, attachment);
      }
      const attachments: Attachment[] = field.value ? [...field.value] : [];
      attachment && attachments.push(attachment);

      FBStore.setValues({
        ...FBStore.values,
        [name]: map(attachments, (attach) =>
          pick(attach, ['id', 'name', 's3link', 'type']),
        ),
      });

      if (FBStore.mode === 'form') {
        FBStore.setValues({
          ...FBStore.values,
          [name]: map(attachments, (attach) =>
            pick(attach, ['id', 'name', 's3link', 'type']),
          ),
        });
        FBDynamicValidatorStore.validate();
      }
      form.setFieldValue(name, attachments);
      submitForm();
    },
    onError: (error) => {
      setIsUploading(false);
      toastError(error as string);
    },
  });

  const handleUpload = (files: File[], lineType, cleanCopyRequired) => {
    if (files.length === 0) {
      return;
    }

    if (lineType === LINE_TYPES.RED_LINE) {
      cleanCopyRequired = false;
    }

    const file = files.pop();
    if (!file) {
      return;
    }
    setIsUploading(true);
    async.start(uploadAction, file, lineType, async, cleanCopyRequired);
    handleUpload(files, lineType, cleanCopyRequired);
  };

  const onChange = (lineType: string, cleanCopyRequired: boolean) => (event: React.FormEvent<HTMLInputElement>) => {
    let isUserAlertedNoDocFiles = false;
    let isUserAlrtedCleanCopyMismatch = false;
    const files = Array.from(event.currentTarget.files || []);
    const filesSize = calculateTotalFileSize(event?.currentTarget?.files);
    const names = files.map(file => file.name);
    if (filesSize > FILE_UPLOAD_LIMIT) {
      handleFileSizeExceedLimit(names, filesSize, event);
      return;
    }

    const filteredFiles = files.filter(
      (file) => {
        const type = file.name.split('.').pop();
        if (type === 'doc') {
          !isUserAlertedNoDocFiles
           && toastError(intl.formatMessage({ id: 'common.attachment.form.DOCERROR' }));
          isUserAlertedNoDocFiles = true;
          return false;
        }

        if (type !== 'docx' && cleanCopyRequired) {
          !isUserAlrtedCleanCopyMismatch
          && toastError(intl.formatMessage({ id: 'common.attachment.form.Create.Clean.Copy.Error' }));
          isUserAlrtedCleanCopyMismatch = true;
          return false;
        }
        return true;
      },
    );

    if (filteredFiles.length === 0) {
      return;
    }
    handleUpload(filteredFiles, lineType, cleanCopyRequired);
  };

  return (
    <>
      {isUploading
      && <Box>
        <CircularProgress color="secondary" />
      </Box>}

      {!isUploading
      && <Box>
        <Presenter
          {
            ...{
              name,
              isUploading,
              upload,
              component,
              multiple,
              form,
              field,
              onChange,
            }
          }
          {...other}
        />
      </Box>
      }
    </>
  );
};

export default withFormik(AttachmentFieldContainer);
