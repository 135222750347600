import { Button, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import cx from 'classnames';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash-can 2.svg';
import { DocumentRevision } from '../../../state/ducks/documentRevisions/types';
import { EMPTY_VALUE_PLACEHOLDER } from '../../components/KendoDataGrid/constants';
import { FBAutocompleteAsync, FBAutocompleteAsyncOption, FBForm, FBFormState } from '../../form.builder';
import useStyles from './ApprovalRequestGrid.styles';
import { ApprovalRequestGridItem } from './ItemsAndDetails.types';
import KendoTextEditor from './KendoTextEditor';

const ApprovalRequestEditGridItem: React.FunctionComponent<{
  disabledOptions: string[]
  formState: FBFormState
  addRevisionToChangeRequest: () => void
  resetAndCancelEdit?: () => void
  showAutoComplete?: boolean
  titleText?: string
  handleRemove?: (docRevId: string) => void
  entry?: ApprovalRequestGridItem
  isLineItemPartCanBeSubstituted?: boolean
  documentRevision?: DocumentRevision
  isDeletionDisabled?: boolean
}> = ({
  disabledOptions,
  formState,
  addRevisionToChangeRequest,
  resetAndCancelEdit,
  showAutoComplete = true,
  titleText,
  handleRemove,
  isLineItemPartCanBeSubstituted,
  entry,
  documentRevision,
  isDeletionDisabled,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const showDeleteButton = !location.pathname.includes(entry?.docRevId) && !isDeletionDisabled;

  const [autoUpdateChecked, setAutoUpdateChecked] = useState(entry?.autoUpdate);

  return (
    <FBForm {...{ formState }} >
      <div className={cx(classes.enclosingDiv, classes.enclosingDivEditItem)} data-cy="enclosing-div-edit-item">
        <div className={cx(classes.itemRow, classes.editItemRow)} data-cy="ar-item-edit-row">
          {isLineItemPartCanBeSubstituted && <div className={cx(classes.cellautoUpdate, classes.hiddenLegend)}>
            <FormControlLabel
              color="primary"
              control={
                <MuiCheckbox
                  checked={autoUpdateChecked}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAutoUpdateChecked(event.target.checked);
                    formState.setFieldValue('autoUpdate', event.target.checked);
                  }}
                  name="autoUpdate"
                  color="primary"
                />
              }
              label=""
            />
          </div> }
          <div className={cx(classes.cellOne, classes.hiddenLegend)}>
            { showAutoComplete
              && <FBAutocompleteAsync
                name="proposedDocumentRevision"
                label="form.builder.document"
                placeholder="form.builder.select"
                optionId={FBAutocompleteAsyncOption.DRAFTdocumentRevisions}
                optionValueType="id"
                rules="required"
                {...{ disabledOptions }}
                shouldReloadOnInit
                autoCompleteClasses={{ option: classes.option }}
              />
            }
            { !showAutoComplete && <span>{titleText}</span>}
          </div>
          <div className={cx(classes.cellRev, classes.itemLeftPadding, classes.hiddenLegend)}>
            {entry?.revisionFormTo ?? (documentRevision?.revision ?? EMPTY_VALUE_PLACEHOLDER)}
          </div>
          <div className={cx(isLineItemPartCanBeSubstituted ? classes.smallCellOther : classes.cellOther,
            classes.itemLeftPadding, classes.hiddenLegend)}>
            <KendoTextEditor
              name="descriptionOfChange"
              label="form.builder.description.of.change"
              placeholder="form.builder.enter"
              rules="required"
            />
          </div>
          <div className={cx(isLineItemPartCanBeSubstituted ? classes.smallCellOther : classes.cellOther,
            classes.itemLeftPadding, classes.hiddenLegend)}>
            <KendoTextEditor
              name="justificationOfChange"
              label="form.builder.justification.of.change"
              placeholder="form.builder.enter"
              rules="required"
            />
          </div>
          <div className={cx(classes.cellWhereUsed, classes.itemLeftPadding, classes.hiddenLegend)} data-cy="edit-where-used">
            {EMPTY_VALUE_PLACEHOLDER}
          </div>
        </div>
        <div className={classes.actionButtonChildDiv}>
          { showDeleteButton && <Button className={classes.actionButton}>
            <DeleteIcon className={classes.iconButton}
              onClick={() => {
                resetAndCancelEdit?.();
                handleRemove?.(entry?.docRevId as string);
              }} />
          </Button> }
          <Button data-cy="clear-record" className={classes.actionButton} onClick = {resetAndCancelEdit}>
            <ClearIcon className={classes.iconButton} />
          </Button>
          <Button data-cy="done-record"
            className={cx(classes.actionButton, classes.actionButtonDone)} onClick = {addRevisionToChangeRequest}>
            <DoneIcon className={classes.iconButton} />
          </Button>
        </div>
      </div>
    </FBForm>
  );
};

export default ApprovalRequestEditGridItem;
