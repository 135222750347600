import { Box } from '@material-ui/core';
import cx from 'classnames';
import { cloneDeep, get, isEmpty, kebabCase, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DEFAULT_DATA_STATE, Mode, MODE_FIELD } from '.';
import { translate } from '../../../../../common/intl';
import { Button } from '../../../../components/forms/fields-next';
import KendoDataGrid from '../../../../components/KendoDataGrid/KendoDataGrid';
import { withThemeNext } from '../../../../layout/theme-next';
import Actions from '../../../FBApprovalMatrixRoles/components/Actions';
import { FB } from '../../../helpers';
import useStyles from './styles';
import { GridItem, GridProps } from './types';

function normalizeData <T> (data: T[]): T[] {
  return cloneDeep(data).map(dataItem => {
    dataItem[MODE_FIELD] = Mode.show;
    return dataItem;
  });
}

function Grid<T> (props: GridProps<T>): React.ReactElement {
  const {
    data: originData,
    schema,
    disabled, onSave,
    generateNewItem,
    withoutStatus,
    ...restProps
  } = props;
  const classes = useStyles();

  const [data, setData] = useState<Array<GridItem<T>>>(normalizeData(originData));
  const [activeItem, setActiveItem] = useState<GridItem<T>>();
 const { workspaceState, formState } = FB.useStores();

  useEffect(
    () => {
      setData(normalizeData(originData));
    },
    [originData],
  );

  useEffect(
    () => {
      setData((prevState) => {
        const data = prevState
          .filter((dataItem) => dataItem?.[MODE_FIELD] !== Mode.add)
          .map((dataItem) => {
            if (activeItem && dataItem.id === activeItem.id) {
              return set(dataItem, MODE_FIELD, dataItem.id ? Mode.edit : Mode.add);
            }
            return set(dataItem, MODE_FIELD, Mode.show);
          });

        if (activeItem?.[MODE_FIELD] === Mode.add) {
          return [...data, activeItem];
        }

        return data;
      });
    },
    [activeItem],
  );

  const isAdding = data.some((dataItem) => dataItem[MODE_FIELD] === Mode.add);

  const handleAdd: React.MouseEventHandler = () => {
    const newItem = {
      ...generateNewItem?.(),
      [MODE_FIELD]: Mode.add,
    } as GridItem<T>;

    setActiveItem(newItem);
  };

  const handleRowClick: GridProps<T>['onRowClick'] = ({ dataItem }) => {
    if (activeItem?.id !== dataItem.id) {
      setActiveItem(cloneDeep(dataItem));
    }
  };
  const requiredSchemaFields = schema.filter(column => column.required).map(column => column.field as string);

  const handleItemSave = (): void => {
    if (!activeItem) {
      return;
    }

    const validationErrors = requiredSchemaFields.reduce((result, field) => {
      const value = get(activeItem, field);
      const error = !value ? 'validators.required' : undefined;
      return {
        ...result,
        ...(error ? { [field]: error } : {}),
      };
    }, {});

    const newData = data.map((dataItem) => {
      if (activeItem && dataItem.id === activeItem.id) {
        if (validationErrors) {
          return set(activeItem, 'errors', validationErrors);
        }
        return set(activeItem, MODE_FIELD, Mode.show);
      }
      return dataItem;
    });

    setData(newData);

    if (!isEmpty(validationErrors)) {
      return;
    }

    onSave?.(newData);
    workspaceState?.saveDocRev(formState?.getValues());
    setActiveItem(undefined);
  };

  const handleItemDiscard = () => setActiveItem(undefined);

  const handleItemChange: GridProps<T>['onItemChange'] = ({ field, value }) => {
    if (!activeItem || !field) {
      return;
    }

    setActiveItem(
      set(activeItem, field, value),
    );
  };

  const rowRender: GridProps<T>['rowRender'] = (row, props) => {
    const { dataItem } = props;

    const isUpdating = [Mode.add, Mode.edit].includes(dataItem[MODE_FIELD]);
    const isInactive = dataItem?.docType?.active === false;

    const cyLabel = kebabCase(dataItem?.docType?.name?.toLowerCase())
      || (dataItem[MODE_FIELD] === Mode.add ? 'new' : 'empty');
    const dataCy = `row-${cyLabel}`;

    if (!isUpdating) {
      return React.cloneElement(
        row,
        {
          ['data-cy' as string]: dataCy,
          className: cx(row.props.className, { [classes.inactive]: isInactive }),
        },
      );
    }

    const actions = (
      <td className={classes.actionsTd}>
        <div className={classes.line} />
        <Actions
          dataItem={dataItem}
          onConfirm={handleItemSave}
          onDiscard={handleItemDiscard}
        />
      </td>
    );

    return React.cloneElement(
      row,
      {
        ['data-cy' as string]: dataCy,
        className: cx(row.props.className, classes.updating),
      },
      row.props.children,
      actions,
    );
  };

  // @todo: Update rowspan without hack.
  useEffect(() => {
    document.querySelectorAll('.k-grid-header-wrap tr[aria-rowindex="2"] th').forEach((element) => {
      element.setAttribute('rowspan', '2');
    });
  }, []);

  const withButton = !disabled;

  return (
    <>
      <Box className={classes.gridContainer}>
        <KendoDataGrid
          className={cx(classes.grid, { [classes.gridWithButton]: withButton })}
          data={data}
          schema={schema}
          rowRender={rowRender}
          dataItemKey="id"
          onRowClick={!disabled ? handleRowClick : undefined}
          onItemChange={handleItemChange}
          defaultDataState={!withoutStatus && data.length ? DEFAULT_DATA_STATE : undefined}
          filterable
          fullWidth
          fullHeight
          hasBoxScrollbars
          {...restProps}
        />
      </Box>
      {withButton && (
        <Button
          data-cy="button-add"
          className={classes.buttonAdd}
          kind="add"
          disabled={isAdding}
          onClick={handleAdd}
        >
          {translate('form.builder.am.add')}
        </Button>
      )}
    </>
  );
}

export default withThemeNext(Grid);

export * from './constants';
