import { filter, isUndefined } from 'lodash';
import { action, observable, set } from 'mobx';
import { FBBookmarkItem } from '..';

class FBBookmarkState {
  @observable public bookmarks?: FBBookmarkItem[];
  @observable public loading?: boolean;

  @action public setLoading = (state: boolean) => set(this, 'loading', state);

  @action public addBookmark = (bookmark?: FBBookmarkItem, at?: number) => {
    if (!bookmark) { return; }
    const { name } = bookmark;
    let newBookmarks = filter(this.bookmarks, (bookmark) => bookmark.name !== name);
    at = isUndefined(at) ? (newBookmarks.length || 0) : at;
    newBookmarks = newBookmarks.slice(0) || [];
    newBookmarks?.splice(at, 0, bookmark);
    set(this, 'bookmarks', newBookmarks);
  };

  @action public removeBookmarks = (names?: Array<string | undefined>) => {
    if (!names) { return; }
    const newBookmarks = filter(this.bookmarks, (bookmark) => !names.includes(bookmark.name || ''));
    set(this, 'bookmarks', newBookmarks);
  };

  @action public removeBookmark = (name: string) => {
    if (!name) { return; }
    set(this, 'bookmarks', filter(this.bookmarks, (bookmark) => bookmark.name !== name));
  };
}

export default FBBookmarkState;
