/* eslint-disable max-len */
/* eslint-disable no-template-curly-in-string */

export enum FBEndpoint {
  AutocompleteByOptionId = '/autocomplete_options/${optionId}',
  PrivateUpload = '/attachments/private_upload_url',
  FileUploadCandidates = '/document/file_upload_candidates',
  AssetUrl = '/attachments/assetUrl',
  AttachmentUpload = '/attachments/upload',
  AttachmentPatch = '/attachments/${id}',
  AttachmentById = '/attachments/${id}',
  AttachmentsByIds ='/attachments?ids=${ids}',
  AllDocRevAttachments ='/document_revisions/all_attachments/${id}',
  MaterialAndEquipment = '/document_revisions/${id}/materials_and_equipment',
  PostInlineApprove = '/document_revisions/${documentId}/inline_approve',
  DynamicControl = '/document_revisions/dynamic_forms',
  FetchDocumentRevision = '/document_revisions/${documentId}',
  FetchDocId = '/documents/proposed_docid?documentTypeId=${documentTypeId} ',
  DocumentRevisionsUrl = '/document_revisions',
  FetchDocumentRevisionByAcronym =
  '/document_revisions?documentTypeAcronym=${documentTypeAcronym}&status%5B%5D=RELEASED',
  ReceivedItemsQuantity = '/documents/receivable_quantity/${documentId}',
  CreateTemplateUrl = '/hello_sign/template',
  SendSignatureUrl = '/hello_sign/send_signature_request',
  CancelSignatureUrl = '/hello_sign/cancel_signature_request',
  AttachTemplateUrl = '/hello_sign/template/attach_predefined',
  ApprovalMatrix = '/document_type/approval_matrix',
  POApprovalMatrix = '/purchase_order/approval_matrix',
  Currency = '/currency/${symboName}',
  DocumentRevisionAudit = '/document_revisions/${documentId}/aggregated_audit',
  Tasks = '/tasks',
  Task = '/tasks/${id}',
  TaskAbandon = '/tasks/${id}/abandon',
  TaskDone = '/tasks/${id}/done',
  Document = '/documents/${id}',
  RequiredApprovers = '/document_revisions/required_approvers',
  Approvals = '/approvals',
  ApprovalsTransition = '/approvals/${id}/${transition}',
  ApprovalsUpdate = '/approvals/${id}/update',
  SetApprovalStatus = '/approvals/${approvalId}/${status}',
  PatchDocumentRevision = '/document_revisions/${docRevId}',
  HistoryMaintenance = '/document_revisions/history_maintenance/${id}',
  LotsToPart = '/document_revisions/lots_of_part/${id}',
  PartsOfLot = '/document_revisions/parts_of_lot/${id}',
  Lots = '/document_revisions?docTypeGroupOptions%5B%5D=CAN_CREATE_INSPECTION&docTypeGroupOptions%5B%5D=ENGINEERING_BUILD&status%5B%5D=DRAFT&status%5B%5D=PENDING_CHANGE&status%5B%5D=SUBMITTED&status%5B%5D=IN_REVIEW&status%5B%5D=APPROVED&status%5B%5D=RELEASED',
  Parts = '/document_revisions?docTypeGroupOptions%5B%5D=MATERIAL&status%5B%5D=DRAFT&status%5B%5D=PENDING_CHANGE&status%5B%5D=SUBMITTED&status%5B%5D=IN_REVIEW&status%5B%5D=APPROVED&status%5B%5D=RELEASED',
  FetchDocumentType = '/document_type/${documentTypeId}',
  StartMpi = '/lhr/start_mpi',
  FetchAvailableLhrForPart = '/lhr/available_lhr',
  FetchLhrDetails = '/lhr',
  FecthAvailableMaintenanceForms = '/documents/available_maintenance_forms',
  FetchAutocompleteValue = '/autocomplete_options/${id}/${optionId}',
  UseOverride = '/lhr/lhrs/${id}/use_override',
  RemoveOverride = '/lhr/lhrs/${id}/remove_override/${refId}',
  Notes = '/notes',
  DocumentRevisionPatch = '/document_revisions/${id}',
  ChangeRequestPatch = '/change_requests/${id}',
  LockedFields = '/document_revision_field_locks/${id}',
  LockFields = '/document_revision_field_locks',
  LockFieldsExpire = '/document_revision_field_locks/${id}/expire',
  CreateLot = '/receivable/${receivableId}/create_lot',
  AttachmentUnzipping = '/attachment_job/start_unzipping',
  ZIPStatus = '/attachment_job/connected_to/${attachmentId}',
  Favourites = '/user_favourites',
  ModifyFavourites = '/user_favourites/${id}',
  ApprovalLevels = '/document_revisions/threshold',
  GlobalSearch = '/global_search/documents',
  TrainingRecord = '/training_record',
  PrintLotLabels ='/zebra/labels/lot',
  PrintEquipmentLabels ='/zebra/labels/equipment',
  RelatedEquipment ='/related_equipment',
}
