import { createStyles } from '@material-ui/core';
import Colors from '../../../../../layout/theme/utils/colors';
import { NextTheme } from '../../../../../layout/theme-next/provider';

export default (theme: NextTheme) =>
  createStyles({
    form: {
      minWidth: 400,
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '5px 5px',
      position: 'static',
      width: '100px',
      height: '25px',
      left: 'calc(50% - 92px/2 + 139px)',
      top: 'calc(50% - 25px/2)',
      background: Colors.navy,
      borderRadius: '200px',
      flex: 'none',
      order: 1,
      flexGrow: 0,
      margin: '0px 20px',
      float: 'right',
      clear: 'right',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '15px',
      color: Colors.white,
    },
    pendingBox: {
      flexDirection: 'column',
      height: 'auto',
      width: '410px',
      marginBottom: '12px',
      overflowY: 'auto',
      top: '48px',
      left: '20px',
      border: '1px solid',
      borderColor: Colors.sky_blue,
      display: 'flex',
      padding: '20px',
      backgroundColor: Colors.white,
      boxSizing: 'border-box',
      alignItems: 'flex-start',
      borderRadius: '8px',
    },
    closeLaterDiscription: {
      position: 'static',
      left: '10.42%',
      right: '0%',
      top: '0%',
      bottom: '0%',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      textAlign: 'right',
      color: Colors.almost_black,
      flex: 'none',
      order: 1,
      flexGrow: 0,
    },
    closeLaterSubmitButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '5px 5px',
      position: 'static',
      width: '136px',
      height: '25px',
      left: '274px',
      top: 'calc(50% - 25px/2)',
      background: Colors.navy,
      borderRadius: '200px',
      flex: 'none',
      order: 1,
      flexGrow: 0,
      margin: '0px 20px',
      float: 'right',
      clear: 'right',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '15px',
      color: Colors.white,
    },
    ScheduleIcon: {
      margin: '0px 5px 0px 0px',
    },
    [theme.media.mobile]: {
      pendingBox: {
        width: '100%',
        padding: 0,
      },
      form: {
        minWidth: '100%',
      },
      closeLaterDiscription: {
        display: 'flex',
        flexWrap: 'nowrap',
        textAlign: 'center',
      },
      closeLaterSubmitButton: {
        width: '100%',
        margin: `0 ${theme.spacing(2)}px`,
        position: 'initial',
      },
    },
  });
