/* eslint-disable max-len */
import React from 'react';

export const renderDefaultAttachment = (color, name) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
    <g id="file-vvt" transform="translate(-1 -1)">
      <path id="Path_2246" data-name="Path 2246" d="M5,43v3a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V43Z" fill="#d4dbe1" />
      <path id="Path_2247" data-name="Path 2247" d="M43,21V14H31a1,1,0,0,1-1-1V1H6A1,1,0,0,0,5,2V21Z" fill="#d4dbe1" />
      <path id="Path_2248" data-name="Path 2248" d="M32,2.586V12h9.414Z" fill="#b4c0ca" />
      <path id="Path_2249" data-name="Path 2249" d="M46,23H2a1,1,0,0,0-1,1V40a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V24A1,1,0,0,0,46,23Z" fill={color} />
      <text id={name} transform="translate(24 36)" fill="#fff" fontSize="11" fontFamily="Sora, sans-serif" fontWeight="800" letterSpacing="-0.02em"><tspan x="-7.904" y="0">{name}</tspan></text>
    </g>
  </svg>);

export const renderCOADefaultAttachment = (color, name) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="47.951" viewBox="0 0 46 47.951">
    <g id="Group_3807" data-name="Group 3807" transform="translate(-620 -317.049)">
      <g id="file-vvt" transform="translate(619 317)">
        <path id="Path_2249" data-name="Path 2249" d="M46,23H2a1,1,0,0,0-1,1V40a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V24A1,1,0,0,0,46,23Z" fill={color} />
        <text id={name} transform="translate(24 36)" fill="#fff" fontSize="11" fontFamily="Sora, sans-serif" fontWeight="800" letterSpacing="-0.02em"><tspan x="-11.768" y="0">{name}</tspan></text>
      </g>
      <path id="Path_2606" data-name="Path 2606" d="M-2663.674-21846.227v-19.725h26.524l11.3,11.3v8.428" transform="translate(3288 22184)" fill="#fff" stroke="#8396a5" strokeWidth="2" />
      <path id="Path_2607" data-name="Path 2607" d="M-2663.674-21865.953v4.951h37.82v-4.951" transform="translate(3288 22225.002)" fill="#fff" stroke="#8396a5" strokeWidth="2" />
    </g>
  </svg>);

export const renderRedLineAttachment = (color, name) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
    <g id="file-vvt" transform="translate(-1 -1)">
      <path id="Path_2246" data-name="Path 2246" d="M5,43v3a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V43Z" fill="#f05a5c" />
      <path id="Path_2247" data-name="Path 2247" d="M43,21V14H31a1,1,0,0,1-1-1V1H6A1,1,0,0,0,5,2V21Z" fill="#d4dbe1" />
      <path id="Path_2248" data-name="Path 2248" d="M32,2.586V12h9.414Z" fill="#b4c0ca" />
      <path id="Path_2249" data-name="Path 2249" d="M46,23H2a1,1,0,0,0-1,1V40a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V24A1,1,0,0,0,46,23Z" fill={color} />
      <text id={name} transform="translate(24 36)" fill="#fff" fontSize="11" fontFamily="Sora, sans-serif" fontWeight="800" letterSpacing="-0.02em"><tspan x="-15.636" y="0">{name}</tspan></text>
    </g>
  </svg>);

export const renderCOAPrimaryAttachment = (color, name) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="51" viewBox="0 0 49 51">
    <g id="Group_3806" data-name="Group 3806" transform="translate(-620 -314)">
      <g id="file-vvt" transform="translate(619 317)">
        <path id="Path_2249" data-name="Path 2249" d="M46,23H2a1,1,0,0,0-1,1V40a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V24A1,1,0,0,0,46,23Z" fill={color} />
        <text id={name} transform="translate(24 36)" fill="#fff" fontSize="11" fontFamily="Sora, sans-serif" fontWeight="800" letterSpacing="-0.02em"><tspan x="-15.636" y="0">{name}</tspan></text>
      </g>
      <path id="Path_2606" data-name="Path 2606" d="M-2663.674-21846.227v-19.725h26.524l11.3,11.3v8.428" transform="translate(3288 22184)" fill="#fff" stroke="#8396a5" strokeWidth="2" />
      <path id="Path_2607" data-name="Path 2607" d="M-2663.674-21865.953v4.951h37.82v-4.951" transform="translate(3288 22225.002)" fill="#fff" stroke="#8396a5" strokeWidth="2" />
      <g id="Group_3905" data-name="Group 3905" transform="translate(340 -692)">
        <circle id="Ellipse_252" data-name="Ellipse 252" cx="10" cy="10" r="10" transform="translate(309 1006)" fill="#f4f5f7" />
        <g id="Group_3904" data-name="Group 3904" transform="translate(-275.02 -26)">
          <circle id="Ellipse_243" data-name="Ellipse 243" cx="6" cy="6" r="6" transform="translate(588.02 1036)" fill="#fff" />
          <g id="ic_stars_24px" transform="translate(587.045 1035)">
            <path id="Path_2554" data-name="Path 2554" d="M8.991,2A7,7,0,1,0,16,9,6.995,6.995,0,0,0,8.991,2Zm2.967,11.2L9,11.413,6.038,13.2l.784-3.366-2.61-2.26,3.443-.294L9,4.1l1.344,3.17,3.443.294-2.61,2.26Z" transform="translate(-2 -2)" fill="#06f" />
          </g>
        </g>
      </g>
    </g>
  </svg>);

export const renderPrimaryAttachment = (color, name, width = 49, height = 49) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <g id="file-vvt" transform="translate(-1 2)">
      <path id="Path_2246" data-name="Path 2246" d="M5,43v3a1,1,0,0,0,1,1H42a1,1,0,0,0,1-1V43Z" fill="#d4dbe1" />
      <path id="Path_2249" data-name="Path 2249" d="M46,23H2a1,1,0,0,0-1,1V40a1,1,0,0,0,1,1H46a1,1,0,0,0,1-1V24A1,1,0,0,0,46,23Z" fill={color} />
      <text id={name} transform="translate(24 36)" fill="#fff" fontSize="11" fontFamily="Sora, sans-serif" fontWeight="800" letterSpacing="-0.02em"><tspan x="-15.636" y="0">{name}</tspan></text>
      <path id="Rectangle_2668" data-name="Rectangle 2668" d="M1,0H38a0,0,0,0,1,0,0V20a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V1A1,1,0,0,1,1,0Z" transform="translate(5 1)" fill="#d4dbe1" />
      <g id="Group_3904" data-name="Group 3904" transform="translate(-279 -1008)">
        <circle id="Ellipse_252" data-name="Ellipse 252" cx="10" cy="10" r="10" transform="translate(309 1006)" fill="#f4f5f7" />
        <g id="Group_3904-2" data-name="Group 3904" transform="translate(-275.02 -26)">
          <circle id="Ellipse_243" data-name="Ellipse 243" cx="6" cy="6" r="6" transform="translate(588.02 1036)" fill="#fff" />
          <g id="ic_stars_24px" transform="translate(587.045 1035)">
            <path id="Path_2554" data-name="Path 2554" d="M8.991,2A7,7,0,1,0,16,9,6.995,6.995,0,0,0,8.991,2Zm2.967,11.2L9,11.413,6.038,13.2l.784-3.366-2.61-2.26,3.443-.294L9,4.1l1.344,3.17,3.443.294-2.61,2.26Z" transform="translate(-2 -2)" fill="#06f" />
          </g>
        </g>
      </g>
    </g>
  </svg>);
