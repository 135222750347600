import { has } from 'lodash';
import React, { useEffect } from 'react';
import { FB, FBFormProps, FBFormState } from '..';
import { SM } from '../../../App';
import { checkIsDocumentReceivable } from '../../documentRevision/helpers/checkDocumentGroup';

export let FBFormStateContext = React.createContext<any>({});
export const withFBForm = <T extends FBFormProps>(
  Component: React.FunctionComponent<T>,
) => {
  const Comp = ({
    initialValues = {},
    workspaceMode,
    errorValidation = true,
    initialValidation = false,
    includeLocking = false,
    formState,
    schema,
    children,
    ...props
  }: T) => {
    const { workspaceState } = FB.useStores();
    const { _documentRevisionFormState } = SM.useStores();
    !formState && (formState = FB.useRef<FBFormState>(FBFormState, {
      schema,
      initialValues: initialValues?.formInput || initialValues,
      workspaceMode: workspaceMode || workspaceState?.mode || 'form',
      workspaceState,
    }));

    const isReceivableType
        = checkIsDocumentReceivable(_documentRevisionFormState?.document?.documentType?.groupOptions);
    if (!(isReceivableType && has(initialValues, 'po-items-built-in'))) {
      FBFormStateContext = React.createContext<any>(formState?.getValues());
    }

    formState.setIncludeLocking(includeLocking);
    initialValidation && workspaceMode !== 'design' && formState.validate();

    useEffect(() => {
      if (!formState) { return; }
      formState.errorValidation = errorValidation;
      formState.workspaceMode = workspaceMode;
    }, [formState, errorValidation, workspaceMode]);

    useEffect(() => {
      if (!formState) { return; }
      formState.initialValues = initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    return Component({
      ...(props as T),
      initialValues,
      formState,
      workspaceMode,
      children,
      schema,
      includeLocking,
    });
  };

  return (props: T) => Comp(props);
};
