import { Box } from '@material-ui/core';
import React from 'react';
import { FBForm, FBPOReceiveFormProps, FBTextField } from '..';
import { FormContext } from '../../components/forms/FormContext';
import { withFBPOReceiveForm } from './FBPOReceiveForm.wrap';

const FBPOReceiveForm: React.FunctionComponent<FBPOReceiveFormProps> = ({
  children,
  item,
}) => (
  <FBForm>
    <FormContext.Provider value={{ submitOnChange: false }}>
      <FBTextField
        label="form.builder.field.part.number.or.service"
        defaultValue={item?.partNumberDocId || item?.serviceId}
        disabled={true}
        name="partNumberService"
        hidden={!(item?.partNumberDocId || item?.serviceId)}
      />
      <FBTextField
        label="form.builder.field.supplier.part.number"
        defaultValue={item?.supplierPartNumber}
        disabled={true}
        name="supplierPartNumber"
        hidden={!item?.supplierPartNumber}
      />
      <FBTextField
        label="form.builder.description"
        defaultValue={item?.partNumberTitle || item?.description}
        name="description"
        disabled={true}
      />
      <FBTextField
        label="form.builder.field.ordered"
        defaultValue={item?.order}
        name="ordered"
        disabled={true}
      />
      <Box display="flex" justifyContent="space-between">
        <Box width="100%" marginRight="10px">
          <FBTextField
            inputProps={{ type: 'number', min: '0' }}
            defaultValue={item?.received}
            label="form.builder.quantity.received"
            name="received"
            rules="required|min:0"
          />
        </Box>
        <Box width="100%" marginLeft="10px">
          <FBTextField
            label="form.builder.field.units"
            defaultValue={item?.units}
            name="units"
            disabled={true}
          />
        </Box>
      </Box>
      {children}
    </FormContext.Provider>
  </FBForm>
);

export default withFBPOReceiveForm(FBPOReceiveForm);
