import Colors from './colors';

export const spacing = 8;
export const shape = {
  borderRadius: 6,
};

export const GRADIENT = `linear-gradient(263.07deg, ${Colors.secondary} 0, ${Colors.primaryGradient} 29.75%, ${Colors.primary})`;

export const TRANSITON_BOUNCE = 'cubic-bezier(.5, 1.6, .4, .8)';
