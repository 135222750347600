export enum FBFieldName {
  Context = 'input-context',
  EditorProps = 'editorProperties',
  RequestApproval = 'fb-request-',
  TemplateName = 'fb-template-name',
  ApproveReasoning = '-reasoning',
  ApprovalState = 'approvalFieldState-',
  MaterialSection = 'fb-material-section-',
  EquipmentSection = 'fb-equipment-section-',
  ProcedureDiff = 'fb-procedure-diff',
  MPIProcedureId = 'fb-mpiprocedure-ref',
  GRPTTrainer = 'fb-grpt-trainer',
  GRPTTraineeList = 'fb-grpt-trainee-list',
}
