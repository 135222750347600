import { round, unionBy } from 'lodash';
import { useObserver } from 'mobx-react';
import { useSelector } from 'react-redux';
import { FB } from '..';
import { authSelectors } from '../../../state/ducks/auth';
import { Group } from '../../../state/ducks/auth/types';
import { companySelectors } from '../../../state/ducks/company';
import { materialFlowACLSelectors } from '../../../state/ducks/materialFlowAcl';
import { MaterialFlowAclRule } from '../../../state/ducks/materialFlowAcl/types';
import { DEDUCTABLE_TRANSFER_TYPES, INCREMENTABLE_LOT_TRANSFER_TYPES, INITIAL_QUANTITY_FIELD, MAX_DECIMALS, RULE_FIELDS_TO_CHECK, TRANSFERS_FIELD } from './constants';
import { AclRuleFieldsToCheck, AllowedOptions, CalculableLotTransfer, CompanyLocation, DocumentRevisionOption, LotTransfer, LotTransferType, PredictQuantityFunction, SelectedOptions } from './types';

export const getOptionLabel = (option: DocumentRevisionOption): string => {
  const baseLabel = `${option.document?.docId} - rev ${option.displayRevision}`;
  return option.name ? `${baseLabel} - ${option.name}` : baseLabel;
};

interface UseOnHandQuantityType {
  initialQuantity: number
  onHandQuantity: number
  onHandQuantityUnit: string
  predictOnHandQuantity: PredictQuantityFunction
}

export const shapeQuantity = (quantity: number, type: LotTransferType): number => {
  return [LotTransferType.Backflush].includes(type) ? quantity : Math.abs(quantity);
};

const calculateSumForTransfer = (transfer: CalculableLotTransfer, sum: number) => {
  const shouldDeduct = DEDUCTABLE_TRANSFER_TYPES.includes(transfer.type);
  const shouldAdd = INCREMENTABLE_LOT_TRANSFER_TYPES.includes(transfer.type);
  let result = sum;
  if (shouldDeduct) {
    result -= shapeQuantity(transfer.quantity, transfer.type);
  }
  if (shouldAdd) {
    result += shapeQuantity(transfer.quantity, transfer.type);
  }

  return round(result, MAX_DECIMALS);
};

export const useOnHandQuantity = (): UseOnHandQuantityType => {
  const { workspaceState } = FB.useStores();

  const transfers = useObserver(() => {
    const value = workspaceState?.formInputSync.get(TRANSFERS_FIELD) as LotTransfer[] | undefined;
    return value ?? [];
  });
  const initialQuantity = useObserver(() => {
    const value = workspaceState?.formInputSync.get(INITIAL_QUANTITY_FIELD) as number | null;
    return value ?? 0;
  });
  const onHandQuantityUnit = useObserver(() => {
    const value = workspaceState?.formInputSync.get(`${INITIAL_QUANTITY_FIELD}_unit`) as string;
    return value ?? '';
  });

  const onHandQuantity = transfers.reduce(
    (sum, transfer) => calculateSumForTransfer(transfer, sum),
    initialQuantity,
  );

  const predictOnHandQuantity: PredictQuantityFunction = ({ skipTransfer, newTransfer }) => {
    const filteredTransfers = transfers.filter((transfer) => transfer.id !== skipTransfer?.id) ?? [];
    const allTransfers = newTransfer ? unionBy([newTransfer], filteredTransfers) : filteredTransfers;

    return allTransfers.reduce(
      (sum, transfer) => calculateSumForTransfer(transfer, sum),
      initialQuantity,
    );
  };

  return {
    initialQuantity,
    onHandQuantity,
    onHandQuantityUnit,
    predictOnHandQuantity,
  };
};

const checkRuleLocations = (rule: MaterialFlowAclRule, field: 'from' | 'to', set: Set<string>, locations: CompanyLocation[]) => {
  if (rule[field]) {
    set.add(rule[field]);
  } else {
    locations.forEach((option) => {
      set.add(option.id);
    });
  }
};

const getAllowedTransferOptions = (
  rules: MaterialFlowAclRule[],
  locations: CompanyLocation[],
  groups: Group[],
  selectedOptions: SelectedOptions = {},
) => {
  const allowedActions = new Set<string>();
  const allowedFromLocations = new Set<string>();
  const allowedToLocations = new Set<string>();
  const allowedRules = rules.filter(
    rule => rule.groups.length === 0 || rule.groups.some(group => groups.some(it => it.id === group.id)),
  );

  RULE_FIELDS_TO_CHECK.forEach(field => {
    allowedRules.forEach(rule => {
      if (field !== 'type' && selectedOptions.type && rule.action !== selectedOptions.type) return;
      if (field !== 'from' && selectedOptions.from && rule.from?.length && rule.from !== selectedOptions.from) return;
      if (field !== 'to' && selectedOptions.to && rule.to?.length && rule.to !== selectedOptions.to) return;
      if (field === 'to' && rule.action === LotTransferType.Scrap) return;

      if (field === 'type') {
        allowedActions.add(rule.action);
      }
      if (field === 'from' || field === 'to') {
        checkRuleLocations(rule, field, field === 'from' ? allowedFromLocations : allowedToLocations, locations);
      }
    });
  });

  return {
    type: Array.from(allowedActions),
    from: Array.from(allowedFromLocations),
    to: Array.from(allowedToLocations),
  };
};

export const useAllowedLotTransferOptions = (
  selectedOptions: SelectedOptions = {},
): [boolean, AllowedOptions] => {
  const locations = useSelector(companySelectors.getAllLocations) ?? [];
  const rules = useSelector(materialFlowACLSelectors.getRules) ?? [];
  const userGroups = useSelector(authSelectors.currentUserGroups) ?? [];
  const isAclEnabled = useSelector(materialFlowACLSelectors.getIsEnabled);

  if (!isAclEnabled) {
    return [false, { type: [], from: [], to: [] }];
  }

  return [true, getAllowedTransferOptions(rules, locations, userGroups, selectedOptions)];
};

export const useIsTransferAllowed = (
  selectedOptions: SelectedOptions = {},
): [boolean, AclRuleFieldsToCheck[]] => {
  const locations = useSelector(companySelectors.getAllLocations) ?? [];
  const rules = useSelector(materialFlowACLSelectors.getRules) ?? [];
  const userGroups = useSelector(authSelectors.currentUserGroups) ?? [];
  const isAclEnabled = useSelector(materialFlowACLSelectors.getIsEnabled);

  if (!isAclEnabled) {
    return [true, []];
  }

  const allowedTransactions = getAllowedTransferOptions(rules, locations, userGroups, selectedOptions);

  return RULE_FIELDS_TO_CHECK.reduce<[boolean, AclRuleFieldsToCheck[]]>(([result, errors], field) => {
    const selectedOption = String(selectedOptions[field]);
    switch (field) {
      case 'from':
      case 'to': {
        const isAllLocationsAllowed = allowedTransactions[field].length === locations.length;
        const isValid = allowedTransactions[field].includes(selectedOption) || isAllLocationsAllowed;
        return [result && isValid, isValid ? errors : [...errors, field]];
      }
      default:
        return [result, errors];
    }
  }, [true, []]);
};
