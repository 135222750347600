import { find, get, isUndefined, keyBy, map, omit } from 'lodash';
import { action, observable, set } from 'mobx';
import { FBAutocompleteAsyncOption, FBAutocompleteAsyncState, FBProcedureItemConfig, FBProcedureItemType, StartMpiBody } from '..';
import { Document, DocumentRevision } from '../../../state/ducks/documentRevisions/types';
import { FBEndpoint } from '../defaults/FBEndpoint';
import FBRequest from '../FBApi/FBApi.request';
import FBAutocompleteAsyncStore from '../FBAutocompleteAsync/FBAutocompleteAsync.store';
import { COMMON_INITIAL_VALUES, INDIVIDUAL_INITIAL_VALUES } from '../FBInput/FBInput.wrap';

class FBLHRStepState {
  // MARK: @api
  public startMpiApi = new FBRequest<DocumentRevision, StartMpiBody>(FBEndpoint.StartMpi);
  public getDocumentApi = new FBRequest<Document, null>(FBEndpoint.Document);
  public materialsApi = new FBAutocompleteAsyncState({ optionId: this.materialsId });
  public equipmentApi = new FBAutocompleteAsyncState({ optionId: this.equipmentId });
  // MARK @config
  @observable public stepLHRs?: DocumentRevision;
  @observable public materials: any;
  @observable public equipment: any;
  @observable public editedParameters: any;
  @observable public approvals: any;

  public constructor (lhrs?: DocumentRevision) {
    this.stepLHRs = lhrs;
  }

  @action public editItem = (item: FBProcedureItemConfig, type: FBProcedureItemType) => {
    const findItem = find(this.editedParameters, (parameter) => parameter.paramsId === item.paramsId);

    const items = get(this, `${type}`).map((value) => {
      // need this condition if material/eq is edited more than once
      if (value.id === item.paramsId || value.paramsId === item.paramsId) {
        return item;
      }
      return value;
    });
    // need this if material/eq is edited more than once
    if (findItem) {
      const params = map(this.editedParameters, (param) => {
        if (param.paramsId === item.paramsId) {
          return omit(item, [...Object.keys({ ...COMMON_INITIAL_VALUES, ...INDIVIDUAL_INITIAL_VALUES })]);
        }
        return param;
      });
      set(this, 'editedParameters', keyBy(params, 'paramsId'));
      set(this, type, items);

      return;
    }
    const paramsToSet = {
      ...this.editedParameters,
      item: omit(item, [...Object.keys({ ...COMMON_INITIAL_VALUES, ...INDIVIDUAL_INITIAL_VALUES })]),
    };
    set(this, 'editedParameters', keyBy(paramsToSet, 'paramsId'));
    set(this, type, items);
  };

  @action public setApproval = (approvals) => set(this, 'approvals', approvals);
  // MARK: @helpers
  public getLHRsDocument = (id?: string) => {
    if (!id) { return; }
    this.getDocumentApi.set({
      url: FBEndpoint.Document,
      urlValues: { id },
      method: 'get',
    });
  };

  public get materialsId (): string {
    return FBAutocompleteAsyncOption.materials;
  }

  public get equipmentId (): string {
    return FBAutocompleteAsyncOption.equipment;
  }

  public setLHRs = (lhrs) => set(this, 'stepLHRs', lhrs);

  public setMaterials = (materials) => set(this, 'materials', materials);
  public setEquipment = (equipment) => set(this, 'equipment', equipment);
  public setEditedParameters = (editedParameters) => set(this, 'editedParameters', editedParameters);

  public startMpi = (fieldId: string, woId?: string, mpiId?: string, stepNumber?: number) => {
    if (!woId || !mpiId || isUndefined(stepNumber)) { return; }
    this.startMpiApi.set({
      url: FBEndpoint.StartMpi,
      method: 'post',
      body: {
        wo: {
          id: woId,
        },
        mpi: {
          id: mpiId,
        },
        fieldId,
        stepNumber,
      },
    });
  };

  protected findRevision = (id: string, type: FBProcedureItemType): DocumentRevision | undefined => {
    const itemId = type === FBProcedureItemType.materials
      ? this.materialsId
      : this.equipmentId;

    const item = FBAutocompleteAsyncStore.getValue<DocumentRevision>(itemId, id);
    return item;
  };

  public getItem = (id: string, type: FBProcedureItemType): string | undefined => {
    const item = this.findRevision(id, type);
    if (item) {
      return `${item.document?.docId} - ${item.name}`;
    }
    return id;
  };
}

export default FBLHRStepState;
