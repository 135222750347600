import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DocumentTypeByIdForm } from '../../../../state/ducks/documentRevisions/documentType/types';
import AutocompleteField from '../../../components/forms/fields/Autocomplete/Autocomplete';
import Text from '../../../components/Text';
import { DialogHandler } from '../../../hooks/useDialog';
import { styles } from './FormListDialog.styles';

interface FormListDialogProps {
  formIds: DocumentTypeByIdForm[]
  dialog: DialogHandler
  handleSelection: (id: string) => void
}
const FormListDialog: React.FunctionComponent<FormListDialogProps> = ({
  dialog,
  formIds,
  handleSelection,
}) => {
  const [selectedFormId, setSelectedFormId] = useState<string>('');
  const classes = styles();
  const preventPropagation = (event: React.FormEvent) => event.stopPropagation();
  const handleSelect = (id: string) => {
    setSelectedFormId(id);
    handleSelection(id);
  };
  useEffect(() => {
    setSelectedFormId('');
  }, [dialog.isOpen]);
  return (
    <Dialog
      open={dialog.isOpen}
      onSubmit={preventPropagation}
      onClose={dialog.close}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>
        <Text translation="supplier.select.form" />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }} style={{ position: 'relative' }}>
        {
          selectedFormId
          && <div
            style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -45, marginLeft: -23 }}
          >
            <CircularProgress size={45} />
          </div>
        }
        <AutocompleteField
          name="select-form"
          size="sm"
          autocompleteProps={{ id: 'FormListDialog-selectForm' }}
          options={formIds.map((form) => ({
            label: form.name,
            value: form.id,
          }))}
          onClick={(option) => handleSelect(option.value)}
          label="supplier.select.form"
        />
        <Button
          variant="text"
          color="secondary"
          className={classes.cancelButton}
          onClick={dialog.close}
          id="FormListDialog-cancelButton"
        >
          <Text translation="transition.action.cancel" />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FormListDialog;
