import { Box } from '@material-ui/core';
import * as React from 'react';
import Text from '../../../components/Text';

const SupplierSurveySubmitMessage: React.FunctionComponent = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    bgcolor="white"
    width="100%"
    height="100%"
    position="absolute"
  >
    <Text translation="suppliersurvey.submit.button" />
  </Box>
);

export default SupplierSurveySubmitMessage;
