import { Box } from '@material-ui/core';
import { concat, difference, filter, find, includes, isEmpty, last, sortBy, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { ReactComponent as CheckedMark } from '../../../assets/images/check_mark.svg';
import { ReactComponent as UncheckedMark } from '../../../assets/images/uncheck_mark.svg';
import { authSelectors } from '../../../state/ducks/auth';
import { Group } from '../../../state/ducks/auth/types';
import { changeRequestsSelectors } from '../../../state/ducks/changeRequest';
import { Approval, Owner } from '../../../state/ducks/common/types';
import { ApplicationState } from '../../../state/reducers';
import AutcompleteField from '../../components/forms/fields/Autocomplete/Autocomplete';
import { OptionType } from '../../components/forms/fields/Autocomplete/types';
import Text from '../../components/Text';
import { ApproveAndReleaseFormValues } from '../../documentRevision/forms/types';
import Colors from '../../layout/theme/utils/colors';
import { toUniqGroupOptions } from './transform';

interface Props {
  dataProp?: Approval[]
  withoutCurrentUser?: boolean
  documentTypeGroups?: Group[]
  values?: ApproveAndReleaseFormValues
  owner?: Owner
  shouldShowDAMCheck?: boolean
  isDocumentPO: boolean
  checkedGroups: Group[]
  initialGroups: Group[]
  setCheckedGroups: (groups: Group[]) => void
}

const Approvers: React.FunctionComponent<Props> = ({
  dataProp,
  withoutCurrentUser = false,
  documentTypeGroups,
  owner,
  shouldShowDAMCheck = true,
  isDocumentPO,
  checkedGroups,
  setCheckedGroups,
  initialGroups,
}) => {
  // MARK: @selectors
  const availableApprovers = useSelector((state: ApplicationState) => {
    if (withoutCurrentUser) {
      const currentUserId = authSelectors.currentUserId(state);
      return changeRequestsSelectors.getApproversListWithoutCurrentUser(state, currentUserId);
    }
    return changeRequestsSelectors.getApproversListForAssessmentForm(state);
  });

  // MARK: @config
  const joinedEmployees = (groups: Group[]) => groups.map((group) => group.joinedEmployees.map((employee) =>
    ({ group: group.name, id: employee.id, name: employee.user?.name }),
  ));
  const approvers = documentTypeGroups ? joinedEmployees(documentTypeGroups).flat() : [];
  const arrayOfOptions = [
    ...toUniqGroupOptions(approvers), ...availableApprovers,
  ];

  // MARK: @styles
  const styles = {
    multiValueRemove: (base: React.CSSProperties, state: any) =>
      (dataProp && dataProp.some((approver) => approver.approver.id === state.data.value))
        ? { ...base, display: 'none' }
        : base,
  };

  // MARK: @state
  const [selectedApprovers, setSelectedApprovers] = useState<OptionType[]>([]);

  // MARK: @effects

  // MARK: @methods
  /** Check/uncheck DAM/POAM required approval groups based on autocomplete selection
   * @selectedUsers Current autocomplete selection.
   */
  const markGroupChecked = (selectedUsers: OptionType[]) => {
    if (!selectedUsers || isEmpty(selectedUsers)) {
      setCheckedGroups(initialGroups);
      return;
    }
    const uniqGroups = sortBy(uniqBy(documentTypeGroups, 'id'), 'documentStage');
    if (isDocumentPO) { // one user can fullfil only one signature requirement (POAM)
      if (!isEmpty(difference(selectedUsers, selectedApprovers))) { // user added
        const newUserId = last(difference(selectedUsers, selectedApprovers))?.value;
        const checkedGroup
          = find(uniqGroups, (group) =>
            includes(group.joinedEmployees.map((e) => e.id), newUserId)
            && !includes(checkedGroups.map((g) => g.id), group.id));
        if (!checkedGroup) {
          setSelectedApprovers(selectedUsers);
          return;
        }
        checkedGroup.groupApprover = newUserId;
        const newGroups = concat(checkedGroups, checkedGroup);
        setCheckedGroups(newGroups);
      } else if (!isEmpty(difference(selectedApprovers, selectedUsers))) { // user removed
        const removedUserId = last(difference(selectedApprovers, selectedUsers))?.value;
        const newGroups = checkedGroups.filter((group) => group.groupApprover !== removedUserId);
        setCheckedGroups(newGroups);
      }
    } else { // user can fullfil all available signature requirements (DAM)
      const newGroups
        = filter(uniqGroups, (group) =>
          group
            .joinedEmployees
            .some((employee) => (
              selectedUsers
                .map((u) => u.value)
                .includes(employee.id)) || owner?.id === employee.id),
        );
      setCheckedGroups(newGroups);
    }
    setSelectedApprovers(selectedUsers);
  };

  // MARK: @helpers
  const renderGroupApproversResults = () => {
    const uniqGroups = uniqBy(documentTypeGroups, 'id');
    return (
      <Box display="flex" flexWrap="wrap">
        {
          uniqGroups.map((group, index) =>
            <Box marginRight={6} marginBottom={4} key={group.id} display="inline-flex">
              {
                includes(checkedGroups.map((g) => g.id), group.id)
                  ? <CheckedMark data-cy={'checked-' + index} /> : <UncheckedMark data-cy="unchecked" />
              }
              <Box paddingLeft={1} display="initial" data-cy={'group-' + index}>
                <Text message={group.name} />
              </Box></Box>,
          )
        }
      </Box>
    );
  };

  const formatOptionLabel = ({ value, label, groups }) => {
    const uniqueValues = groups ? groups.filter((x, i, a) => a.indexOf(x) === i) : [];
    return (
      <div style={{ display: 'flex' }} key={value}>
        <div>{label}</div>
        <div style={{ marginLeft: '10px', color: Colors.font_gray }}>
          {groups ? uniqueValues.join(',  ') : ''}
        </div>
      </div>);
  };

  const MultiValueLabel = ({ props, data }) => (<components.MultiValueLabel {...props}>
    <Box marginLeft={1}><Text message={data.label} /></Box>
  </components.MultiValueLabel>);

  // MARK: @render
  return (
    <>
      <AutcompleteField
        name="approvals"
        label="common.approvers"
        autocompleteProps={{
          id: 'approvals',
          isMulti: true,
          isClearable: false,
          styles,
          backspaceRemovesValue: false,
          formatOptionLabel,
          components: { MultiValueLabel },
        }}
        options={uniqBy(arrayOfOptions, 'value').filter((e: any) => e.value !== owner?.id) as OptionType[]}
        onChange={(users) => markGroupChecked(users as OptionType[])}
      />
      {documentTypeGroups && shouldShowDAMCheck && renderGroupApproversResults()}
    </>
  );
};

export default Approvers;
