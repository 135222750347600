import { makeStyles } from '@material-ui/core';

export const styles = makeStyles({
  btnAlign: {
    display: 'flex',
    flexDirection: 'row-reverse',
    position: 'absolute',
    zIndex: 1,
    right: 0,
  },
});
