import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import Text from '../../components/Text';
import useStyles from './FBWOApprovals.styles';

const CompletedBlock: React.FC<{
  headerText: string
  itemsCount: number
}> = ({ headerText, itemsCount, children }) => {
  const [isOpened, setIsOpened] = useState(false);
  const toggleOpened = () => {
    setIsOpened((prev) => !prev);
  };

  const classes = useStyles();

  return (
    <List className={classes.list}>
      <ListItem
        data-cy={`approval-block-${headerText}`}
        className={classes.stateListItem}
        onClick={toggleOpened}
      >
        <FontAwesomeIcon className={classNames(classes.icon, {
          [classes.rotateIcon]: isOpened,
        })} icon={solid('circle-chevron-right')} />
        <ListItemText>
          <Box display="flex" alignItems="center">
            <Box
              component="span"
              flexGrow={1}
              className={classNames(classes.itemHeader, {
                [classes.expandedItemHeader]: isOpened,
              })}
            >
              <Text message={headerText} />
            </Box>
            <Box
              component="span"
              justifyContent="flex-end"
              className={classes.itemCount}
            >
              <Text
                translation="common.items.count"
                values={{
                  count: itemsCount,
                }}
              />
            </Box>
          </Box>
        </ListItemText>
      </ListItem>
      <Collapse in={isOpened} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  );
};

export default CompletedBlock;
