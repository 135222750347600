import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 30,
    marginRight: theme.spacing(1.5),
  },
}));
