export const SUB_ITEMS_FIELD = 'childNodes';
export const ID_FIELD = 'customDocId';
export const DOC_ID_FIELD = 'docId';
export const EXCEL_FILE_NAME = 'bomTree.xlsx';
export const DATA_ITEM_KEY = 'id';
export const EXPAND_FIELD = 'expanded';
export const SELECTED_PART_KEY_SEPARATOR = '$$$';
export const MODE_FIELD = 'inEdit';
export const ADD_FIELD = 'isNew';
export const DELETE_FIELD = 'isDeleted';
export const ALTERNATE_PART_KEY = 'alternatePartsList';
export const ALTERNATE_PARTS_COUNT_KEY = 'alternatePartsCount';
export const CELL_EMPTY_VALUE = '-';
export const EQUAL_OPERATOR = 'eq';
export const GREATER_THAN_OPERATOR = 'gt';
export const BOM_TABLE_NAME = 'BOM';
export const TREELIST_CONFIG = {
  sortable: false,
  reorderable: true,
  resizable: true,
  scrollable: 'virtual',
  rowHeight: 50,
};
