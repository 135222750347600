import { apiAction } from '../../factories';
import { documentRevisionsActions } from '../documentRevisions';
import { DOCUMENT_REVISION_ROOT_URL } from '../documentRevisions/constants';
import { DocumentRevision, DocumentRevisionEditRequestBody, RevisionChangeType } from '../documentRevisions/types';
import { RELATED_PARTS_ROOT_URL } from './constants';
import { RelatedPartsChoiceRequestBody, RelatedPartsListItem, RELATED_PARTS_STATUS } from './types';

const createRelatedPart
  = (parentDocumentId: string, parentDocumentRevisionId: string, body: DocumentRevisionEditRequestBody,
    suffixName, async) => apiAction({
    url: `${RELATED_PARTS_ROOT_URL}/${parentDocumentId}/${parentDocumentRevisionId}/child`,
    method: 'post',
    data: body,
    onSuccess: (createdRelatedPart: RelatedPartsListItem) => {
      async.successHandler?.callback({ createdRelatedPart });
    },
  });

const parentPartChoiceAction = (body: RelatedPartsChoiceRequestBody, async) => apiAction({
  url: `${RELATED_PARTS_ROOT_URL}/parent`,
  method: 'post',
  data: body,
  onSuccess (data) {
    async.successHandler?.callback(data);
  },
  onFailure (error, dispatch) {
    async.errorHandler?.callback(JSON.stringify({ msg: error, oldSelection: body.oldSelection }));
  },
});

const subPartStatusCheck = (parentDocumentRevisionId: string, newOption: RELATED_PARTS_STATUS, async) => apiAction({
  url: `${RELATED_PARTS_ROOT_URL}/${parentDocumentRevisionId}/checkSubPartReleased`,
  method: 'get',
  onSuccess () {
    async.successHandler?.callback(newOption);
  },
  onFailure (error) {
    async.errorHandler?.callback(error);
  },
});

const fetchAllRelatedParts
  = (parentDocumentId: string, async) => apiAction({
    url: `${RELATED_PARTS_ROOT_URL}/${parentDocumentId}`,
    method: 'get',
    onSuccess (data) {
      if (async.successHandler?.callback) {
        async.successHandler?.callback(data);
      }
    },
  });

const voidRelatedPart
  = (documentRevisionId: string, revisionChangeType: RevisionChangeType, async) => apiAction({
    url: `${RELATED_PARTS_ROOT_URL}/revisionChangeType/${documentRevisionId}`,
    method: 'patch',
    data: { revisionChangeType },
    onSuccess: (documentRevision: DocumentRevision) => {
      async.successHandler?.callback(
        {
          docRevId: documentRevisionId,
          name: documentRevision.name,
          revisionChangeType: documentRevision.revisionChangeType,
        });
    },
  });

const getRelatedSubPartDocRev
  = (documentRevisionId: string, async: { successHandler:
  { callback: (arg0: { documentRevision: DocumentRevision }) => void } }) => apiAction({
    url: `${DOCUMENT_REVISION_ROOT_URL}/${documentRevisionId}`,
    method: 'get',
    onSuccess: (documentRevision: DocumentRevision, dispatch) => {
      dispatch(documentRevisionsActions.documentRevisionSet(documentRevision));
      async.successHandler?.callback({ documentRevision });
    },
  });

const removeRelatedPart = (docRevId: string, async) => apiAction({
  url: `${RELATED_PARTS_ROOT_URL}/${docRevId}`,
  method: 'delete',
  onSuccess () {
    if (async.successHandler?.callback) {
      async.successHandler?.callback({ docRevId });
    }
  },
});

const updateRelatedPart = (docRevId: string, data, async) => apiAction({
  url: `${RELATED_PARTS_ROOT_URL}/${docRevId}`,
  method: 'patch',
  data,
  onSuccess (relatedPart: RelatedPartsListItem) {
    if (async.successHandler?.callback) {
      async.successHandler?.callback(
        {
          docRevId,
          suffixNumber: relatedPart.suffixNumber,
          suffixName: relatedPart.suffixName,
          rootDocId: relatedPart.rootDocId,
        });
    }
  },
});

export default {
  createRelatedPart,
  parentPartChoiceAction,
  subPartStatusCheck,
  fetchAllRelatedParts,
  voidRelatedPart,
  removeRelatedPart,
  updateRelatedPart,
  getRelatedSubPartDocRev,
};
