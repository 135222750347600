import { useFormikContext } from 'formik';
import { filter, isEmpty, isEqual, map, omit } from 'lodash';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SM } from '../../../App';
import { companySelectors } from '../../../state/ducks/company';
import { DOC_TYPE_GROUP_OPTION } from '../../../state/ducks/documentRevisions/documentType/types';
import { DocumentRevision, ReferenceToEditRequest } from '../../../state/ducks/documentRevisions/types';
import { FBAutocompleteAsyncOption } from '../../form.builder';
import FBAutocompleteAsyncStore from '../../form.builder/FBAutocompleteAsync/FBAutocompleteAsync.store';
import { COMMON_INITIAL_VALUES, INDIVIDUAL_INITIAL_VALUES } from '../../form.builder/FBInput/FBInput.wrap';
import { SubmitFormRelation } from './FormContext.types';

export const FormContext = React.createContext<{ submitOnChange: boolean, isEditing?: boolean }>({ submitOnChange: false });

export const useFormContext = () => {
  const context = useContext(FormContext);
  const formik = useFormikContext();
  const { _formState, _documentRevisionFormState } = SM.useStores();
  const autosaveEnabled = useSelector(companySelectors.getAutosaveEnabled);
  function isOutputSchema (): boolean {
    const values = formik.values as any;
    const { schema: documentSchema = [] } = values?.formDocument?.formTemplate || {};
    return (
      (values?.document?.documentType.groupOptions?.includes(DOC_TYPE_GROUP_OPTION.EDITABLE_SCHEMA))
      && !isEqual(JSON.stringify(_documentRevisionFormState?.schema), JSON.stringify(documentSchema))
    );
  }

  function setFBValue () {
    const values = formik.values as any;
    const workspaceSchema = _documentRevisionFormState?.schema;
    const schema = workspaceSchema?.map((schemaItem) => omit(schemaItem, ['autoFocus', 'autoScrollTo']));
    switch (_formState?.mode) {
      case 'design':
        formik.setFieldValue('formTemplate', {
          outputDocumentTypes: (values.outputDocumentTypes || [])
            .map((outputDoc) => ({ id: outputDoc.value })),
          schema,
        });
        break;
      case 'form':
      case 'formDesign':
        formik.setFieldValue('formDocument', {
          id: values.formDocument?.id,
        });
        formik.setFieldValue('formInput', omit(_formState.getValues(),
          Object.keys({ ...COMMON_INITIAL_VALUES, ...INDIVIDUAL_INITIAL_VALUES })));
        if (_formState.mode === 'formDesign' && isOutputSchema()) {
          formik.setFieldValue('schema', schema);
        }
        break;
      default:
        formik.setFieldValue('formTemplate', undefined);
    }
  }

  function setReferences (relation: SubmitFormRelation) {
    if (!_documentRevisionFormState) { return; }
    const { type, name, prevValue, nextValue } = relation;
    let prevDocument: DocumentRevision | undefined;
    let nextDocument: DocumentRevision | undefined;
    if (relation.type === 'poreceive') {
      if (prevValue) {
        prevDocument = FBAutocompleteAsyncStore.getValue(FBAutocompleteAsyncOption.POList, prevValue.id);
      }
      nextDocument = FBAutocompleteAsyncStore.getValue(FBAutocompleteAsyncOption.POList, nextValue.id);
    }
    if (relation.type === 'autocomplete' && relation.name === 'lot_part') {
      prevDocument = FBAutocompleteAsyncStore.getValue(FBAutocompleteAsyncOption.availableParts, prevValue);
      nextDocument = FBAutocompleteAsyncStore.getValue(FBAutocompleteAsyncOption.availableParts, nextValue);
    }
    const prevValueId = prevDocument?.document.id;
    const nextValueId = nextDocument?.document.id;
    let refValues = _documentRevisionFormState.referenceValue;
    if (!prevValueId) {
      // special case: when prevValue is undefined and there is a value in formInput,
      // we need to remove it from refereces also,
      // made according to issues with autosave(caused by this to other fields)
      const currentPoReference = _documentRevisionFormState?.documentRevision?.formInput?.[name];
      const refWithoutPrevValue = _documentRevisionFormState?.documentRevision?.referenceTo.filter((e) => {
        const docRevIds = e.documentRevisions?.map((i) => i.id);
        if (docRevIds?.includes(currentPoReference)) {
          return null;
        }
        return e;
      });
      if (!isEmpty(refWithoutPrevValue)) {
        refValues = refWithoutPrevValue?.map((e) => ({ id: e.id })) as ReferenceToEditRequest[];
      }
      formik.setFieldValue('referenceTo', [...refValues, { id: nextValueId }]);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      _documentRevisionFormState.referenceValue = [...refValues, { id: nextValueId! }];
      return;
    }
    const poNames = _documentRevisionFormState.getFBNames(type);
    const values: string[] = [];
    // Check for other poreceive values
    map(_formState?.getValues(), (id: string, key) => {
      if (key === name) { return; }
      if (!poNames?.includes(key)) { return; }
      const docRev: DocumentRevision | undefined
        = FBAutocompleteAsyncStore.getValue(FBAutocompleteAsyncOption.POList, id);
      const { document: { id: docId = undefined } = {} } = docRev || {};
      if (!docId) { return; }
      values.push(docId);
    });
    if (!values.includes(prevValueId)) {
      refValues = filter(refValues, (ref) => ref.id !== prevValueId);
    }
    if (nextValueId) {
      formik.setFieldValue('referenceTo', [...refValues, { id: nextValueId }]);
      _documentRevisionFormState.referenceValue = [...refValues, { id: nextValueId }];
      return;
    }
    formik.setFieldValue('referenceTo', refValues);
  }

  const submitForm = (relation?: SubmitFormRelation) => {
    if (!autosaveEnabled) {
      return;
    }

    if (context.submitOnChange) {
      if (_documentRevisionFormState) {
        setFBValue();
      }
      if (relation) {
        if (relation.type === 'poreceive' || (relation.type === 'autocomplete' && relation.name === 'lot_part')) {
          setReferences(relation);
        }
      }
      formik.submitForm();
    }
  };

  return {
    ...context,
    submitForm,
  };
};
