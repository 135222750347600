import { APPROVAL_MATRIX_URL, PO_APPROVAL_MATRIX_URL } from '../../../../ui/form.builder/FBApprovalMatrix/constants';
import { apiAction, apiActionWithCallbacks } from '../../../factories';
import { ApiAction, Handlers } from '../../../types';
import { DOCUMENT_TYPE_BY_CATEGORY_ASYNC, DOCUMENT_TYPE_BY_GROUP_ASYNC, DOCUMENT_TYPE_BY_ID, DOCUMENT_TYPE_BY_ID_ASYNC, DOCUMENT_TYPE_LIST, DOCUMENT_TYPE_LIST_ASYNC, DOCUMENT_TYPE_ROOT_URL, SET_DOCUMENT_TYPES_BY_CATEGORY, TAB_CONFIGURATION_LIST, TAB_CONFIGURATION_LIST_ASYNC, TAB_CONFIGURATION_URL } from './constants';
import { DocumentType, DocumentTypeByCategoryAction, DocumentTypeById, DocumentTypeByIdAction, DocumentTypeListAction, TabConfiguration, TabConfigurationListAction } from './types';
import { FBApprovalMatrixFieldData } from '../../../../ui/form.builder';

const documentTypesList = (documentTypes: DocumentType[]): DocumentTypeListAction => ({
  type: DOCUMENT_TYPE_LIST,
  payload: documentTypes,
});

const docTypeIds = (docTypeIds: DocumentTypeById): DocumentTypeByIdAction => ({
  type: DOCUMENT_TYPE_BY_ID,
  payload: docTypeIds,
});

const tabConfigurationList = (data: TabConfiguration[]): TabConfigurationListAction => ({
  type: TAB_CONFIGURATION_LIST,
  payload: data,
});

const loadList = (): ApiAction<DocumentType[]> => apiAction({
  url: DOCUMENT_TYPE_ROOT_URL,
  method: 'get',
  asyncType: DOCUMENT_TYPE_LIST_ASYNC,
  onSuccess: (documentTypes, dispatch) => {
    dispatch(documentTypesList(documentTypes));
  },
});

const loadTabConfiguration = (): ApiAction<TabConfiguration[]> => apiAction({
  url: TAB_CONFIGURATION_URL,
  method: 'get',
  asyncType: TAB_CONFIGURATION_LIST_ASYNC,
  onSuccess: (data, dispatch) => {
    dispatch(tabConfigurationList(data));
  },
});

const getDocTypeIds = (id: string, handlers: Handlers): ApiAction<DocumentTypeById> => apiActionWithCallbacks({
  url: `${DOCUMENT_TYPE_ROOT_URL}/${id}`,
  method: 'get',
  asyncType: DOCUMENT_TYPE_BY_ID_ASYNC,
  onSuccess: (documentTypes, dispatch) => {
    dispatch(docTypeIds(documentTypes));
  },
  handlers,
});

const getDocTypesByCategory = (
  params: { category: string},
  handlers: Handlers,
): ApiAction<DocumentType[]> =>
  apiActionWithCallbacks({
    url: DOCUMENT_TYPE_ROOT_URL,
    asyncType: DOCUMENT_TYPE_BY_CATEGORY_ASYNC,
    method: 'get',
    params,
    handlers,
    onSuccess: (documentTypes, dispatch) => {
      dispatch(setDocTypesByCategory(documentTypes));
    },
  });

const setDocTypesByCategory = (documentTypes: DocumentType[]): DocumentTypeByCategoryAction => ({
  type: SET_DOCUMENT_TYPES_BY_CATEGORY,
  payload: documentTypes,
});

const getDocTypeByGroup = (
  params: any = {},
  handlers: Handlers,
): ApiAction<DocumentType[]> =>
  apiActionWithCallbacks({
    url: `${DOCUMENT_TYPE_ROOT_URL}/with_forms`,
    asyncType: DOCUMENT_TYPE_BY_GROUP_ASYNC,
    method: 'get',
    params,
    handlers,
  });

const getApprovalMatrix = (handlers: Handlers): ApiAction<FBApprovalMatrixFieldData> => apiActionWithCallbacks({
  url: APPROVAL_MATRIX_URL,
  method: 'get',
  handlers,
});

const getPOApprovalMatrix = (handlers: Handlers): ApiAction<FBApprovalMatrixFieldData> => apiActionWithCallbacks({
  url: PO_APPROVAL_MATRIX_URL,
  method: 'get',
  handlers,
});

const updateDocumentType = (
  id: string,
  body: DocumentType,
  handlers: Handlers,
): ApiAction<DocumentType[]> => apiActionWithCallbacks({
  url: `${DOCUMENT_TYPE_ROOT_URL}/${id}`,
  method: 'patch',
  data: body,
  onSuccess: (m, dispatch) => {
    dispatch(loadList());
  },
  handlers,
});

const deleteDocumentType = (id: string): ApiAction<DocumentType[]> => apiAction({
  url: `${DOCUMENT_TYPE_ROOT_URL}/remove/${id}`,
  method: 'delete',
  onSuccess: (m, dispatch) => {
    dispatch(loadList());
  },
});

const addDocumentType = (
  body: DocumentType,
  handlers: Handlers,
): ApiAction<DocumentType[]> => apiActionWithCallbacks({
  url: `${DOCUMENT_TYPE_ROOT_URL}/add`,
  method: 'post',
  data: body,
  onSuccess: (m, dispatch) => {
    dispatch(loadList());
  },
  handlers,
});

const getApprovalMatrixNco = (
  handlers: Handlers,
): ApiAction<DocumentType[]> => apiActionWithCallbacks({
  url: `${DOCUMENT_TYPE_ROOT_URL}/export_approval_matrix_nco`,
  method: 'get',
  handlers,
});

const getGroupWithGroupOptions = (
  handlers: Handlers,
): ApiAction => apiActionWithCallbacks({
  url: `${DOCUMENT_TYPE_ROOT_URL}/group_with_group_options`,
  method: 'get',
  handlers,
});

export default {
  loadList,
  getDocTypeIds,
  getApprovalMatrix,
  getPOApprovalMatrix,
  getDocTypeByGroup,
  getDocTypesByCategory,
  updateDocumentType,
  deleteDocumentType,
  addDocumentType,
  getApprovalMatrixNco,
  getGroupWithGroupOptions,
  loadTabConfiguration,
};
