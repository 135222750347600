import { RefObject } from 'react';
import useResizeObserver, { ObserverRect } from './useResizeObserver';

type UseElementSizeType<T> = [RefObject<T>, Pick<ObserverRect, 'width' | 'height'>];

function useElementSize<T extends HTMLElement> (): UseElementSizeType<T> {
  const [ref, { width, height }] = useResizeObserver<T>();
  return [ref, { width, height }];
}

export default useElementSize;
