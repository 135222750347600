import { BoxProps, Grid } from '@material-ui/core';
import React from 'react';
import { DataProps } from '../../../state/ducks/common/types';
import { documentRevisionsActions } from '../../../state/ducks/documentRevisions';
import { DocumentRevision } from '../../../state/ducks/documentRevisions/types';
import { toastError } from '../../components/notifications';
import useActionCreator from '../../hooks/useActionCreator';
import useAsync from '../../hooks/useAsync';
import { Translation } from '../../translations/types';
import BuildsPresenter from './builds.presenter';

interface OwnProps {
  label: Translation
  isDraft?: boolean
  docRevId?: string
  showRemoveButton?: boolean
  labelProps?: BoxProps
  labelFont?: BoxProps
}
type Props = OwnProps & DataProps<DocumentRevision[]>;

const BuildsContainer: React.FunctionComponent<Props> = ({
  dataProp,
  label,
  isDraft,
  docRevId,
  showRemoveButton,
  labelProps,
  labelFont,
}) => {
  const async = useAsync<any>({
    onError: (error) => {
      toastError(error as string);
    },
  });
  const removeAction = useActionCreator(documentRevisionsActions.removePreviousBuild);
  const handleRemoveBuild = () => {
    async.start(removeAction, docRevId, async);
  };
  return (
    <Grid xs={12}>
      <BuildsPresenter {...{ label, dataProp, isDraft, handleRemoveBuild, showRemoveButton, labelProps, labelFont }} />
    </Grid>
  );
};

export default BuildsContainer;
