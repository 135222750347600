import { createStyles } from '@material-ui/core';
import Colors from '../../../../../layout/theme/utils/colors';
import { NextTheme } from '../../../../../layout/theme-next/provider';

export default (theme: NextTheme) =>
  createStyles({
    form: {
      minWidth: 400,
      width: 410,
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '5px 5px',
      position: 'static',
      width: '100px',
      height: '25px',
      left: 'calc(50% - 92px/2 + 139px)',
      top: 'calc(50% - 25px/2)',
      background: Colors.navy,
      borderRadius: '200px',
      flex: 'none',
      order: 1,
      flexGrow: 0,
      margin: '0px 20px',
      float: 'right',
      clear: 'right',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: Colors.white,
    },
    cancelButton: {
      float: 'right',
      marginRight: theme.spacing(1),
      position: 'static',
      width: '43px',
      height: '25px',
      left: 'calc(50% - 43px/2 + 51.5px)',
      top: 'calc(50% - 15px/2)',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
      color: Colors.almost_black,
      flex: 'none',
      order: 0,
      flexGrow: 0,
      margin: '0px 20px',
    },
    typography: {
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
    },
    typographyBold: {
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: 0,
    },
    pendingBox: {
      flexDirection: 'row',
      height: 'auto',
      width: '410px',
      marginBottom: '25px',
      overflowY: 'auto',
      top: '48px',
      left: '20px',
      border: '1px solid',
      borderColor: Colors.sky_blue,
      display: 'flex',
      padding: '20px',
      backgroundColor: Colors.white,
      boxSizing: 'border-box',
      alignItems: 'flex-start',
      borderRadius: '8px',
      flexWrap: 'wrap',
    },
    [theme.media.mobile]: {
      form: {
        minWidth: 'auto',
        width: 'auto',
      },
      pendingBox: {
        width: 'auto',
      },
    },
  });
