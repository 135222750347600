import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Colors from '../../../layout/theme/utils/colors';

export const styles = makeStyles((theme: Theme) =>
  createStyles({
    listrootdue: {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 395px)',
      width: '100%',
      paddingLeft: '24px',
      top: 'calc(50% - 18px/2)',
      backgroundColor: Colors.white,
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '15px 20px',
      position: 'relative',
      width: '500px',
      top: '12.5%',
      left: '35.5%',
      background: Colors.sky_blue,
      borderRadius: '8px',
    },
    modalTitle: {
      position: 'static',
      width: '194.98px',
      height: '18px',
      left: 'calc(50% - 194.98px/2 - 107.51px)',
      top: 'calc(50% - 18px/2)',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
      /* Font Black */
      color: Colors.almost_black,
    },
    closemodel: {
      left: '20.55%',
      right: '0%',
      bottom: '22.15%',
    },
    overduetitle: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '15px',
      /* AR AIR Color */
      color: Colors.latest_red_varient,
    },
    lastsenttext: {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '15px',
      color: Colors.font_gray,
    },
    duetitle: {
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '15px',
      /* AR AIR Color */
      color: Colors.latest_yellow_varient,
    },
    listitemicon: {
      position: 'relative',
      left: '-3%',
      right: '77.94%',
      top: '0%',
      bottom: '0%',
      width: '30px',
      height: '30px',
      fontSize: 12,
      fontWeight: 600,
      color: Colors.white,
    },
    listitemtext: {
      position: 'static',
      height: '18px',
      left: 'calc(50% - 99px/2 + 20.5px)',
      top: 'calc(50% - 18px/2)',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'center',
    },
    emailbutton: {
      position: 'static',
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
      margin: 0,
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '14px',
      /* identical to box height */
      letterSpacing: '0.5px',
      /* Enlil Navy */
      color: Colors.navy,
      '&:hover': {
        backgroundColor: Colors.white,
      },
    },
    emailPaper: {
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'relative',
      width: '500px',
      height: '133px',
      background: Colors.sky_blue,
      borderRadius: '8px',
      left: '51%',
    },
    confroot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '20px',
      position: 'absolute',
      width: '397px',
      height: '70px',
      left: '33px',
      top: '48px',
      /* White */
      background: Colors.white,
      border: `1px solid ${Colors.sky_blue}`,
      boxSizing: 'border-box',
      borderRadius: '8px',
    },
    confmesg: {
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '14px',
      /* identical to box height */
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      /* Enlil Navy */
      color: Colors.navy,
    },
    confmesgdate: {
      position: 'absolute',
      left: '70.67%',
      top: 'calc(50% - 11px/2)',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '14px',
      /* identical to box height */
      textAlign: 'right',
      letterSpacing: '0.5px',
      /* Font Gray */
      color: Colors.font_gray,
    },
    dueBox: {
      flexDirection: 'column',
      height: 'auto',
      overflowY: 'auto',
      top: '48px',
      left: '20px',
      width: '460px',
      border: `1px solid ${Colors.sky_blue}`,
      display: 'flex',
      padding: '20px',
      backgroundColor: Colors.white,
      boxSizing: 'border-box',
      alignItems: 'flex-start',
      borderRadius: '8px',
    },
    iconupdown: {
      width: '20px',
      position: 'static',
      bottom: '5px',
    },
    successIcon: {
      position: 'relative',
      width: '20px',
      right: '7px',
      top: '7px',
    },
  }),
);
