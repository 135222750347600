import { Box } from '@material-ui/core';
import { isEmpty, size } from 'lodash';
import { Observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  FBConditionalWrapper,
  FBFieldName,
  FBMapWrapper,
  FBPartItemStatusBg,
  FBPartItemStatusBorder,
  FBPartsItemProps,
  FBProcedureItemConfig,
  FBProcedureItemLocale,
  FBSection, FBText,
} from '..';
import { withFBPartsItem } from './FBPartsItem.wrap';

const FBParts: React.FunctionComponent<FBPartsItemProps> = ({
  getItem,
  getLot,
  getItemUrl,
  collection = [],
  loading,
  name,
  type,
  gutter,
}) => (
  <>
    <FBSection
      label={FBProcedureItemLocale[type]}
      name={`${FBFieldName.MaterialSection}${name}`}
      {...{ loading, gutter }}
      {...!gutter && !isEmpty(collection) && { boxProps: { mb: 5 } }}
    />
    {!loading && (
      <FBMapWrapper<FBProcedureItemConfig[]> {...{ collection }}>
        {(item: FBProcedureItemConfig, index) => (
          <Box key={`fb-part-${type}-${index}`}>
            <FBConditionalWrapper
              condition={Boolean(getItemUrl?.(item.title))}
              wrapper={(child) => (
                <Link
                  key={`fb-part-${type}-${index}`}
                  to={getItemUrl?.(item.title) as string}
                  target="_blank"
                  style={{
                    textDecoration: 'none',
                    color: 'initial',
                    fontWeight: 'bold',
                  }}
                >
                  {child}
                </Link>
              )}

            >
              <Observer>
                {() => (
                  <FBText
                    key={`fb-part-${type}-${item.title}-${index}`}
                    locale={`form.builder.procedure.${type}.title`}
                    values={{
                      title: getItem?.(item.title),
                      lot: getLot?.(item.partLot),
                      quantity: item.quantity || 'form.builder.none',
                      unit: item.unit,
                    }}
                    boxProps={{
                      bgcolor: FBPartItemStatusBg[(item.status || 'none')],
                      borderColor: FBPartItemStatusBorder[(item.status || 'none')],
                      border: 1,
                      borderRadius: 4,
                      padding: 1,
                      fontWeight: 'bold',
                      mb: (gutter && index === (size(collection) - 1)) ? 5 : 2,
                    }}
                  />
                )}
              </Observer>
            </FBConditionalWrapper>
          </Box>
        )}
      </FBMapWrapper>
    )}
  </>
);

export default withFBPartsItem(FBParts);
