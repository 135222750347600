import { Dictionary } from 'lodash';

export const FBFormValues: Dictionary<any> = {
  select: '',
  checkbox: '',
  radio: '',
  textfield: '',
  radiogroup: '',
  texteditor: '',
};
